import { lazy } from "react";
import DocumentationPage from "../../pages/DocumentationPage/DocumentationPage";
import ContactsPage from "../../pages/ContactsPage/ContactsPage";
import FeedbackPage from "../../pages/FeedbackPage/FeedbackPage";
import { isSSR } from "../../utils/helpers";

export const OtherRoutes = [
  {
    path: "/:lang/documentation-api/",
    tag: "documentation",
    metaData: "metaDateDocumentation",
    title: "Documentation page",
    description: "Documentation page",
    component: isSSR() ? DocumentationPage : lazy(() => import("../../pages/DocumentationPage/DocumentationPage"))
  },
  {
    path: "/:lang/contacts/",
    tag: "contacts",
    metaData: "metaDateFeedback",
    title: "Contacts page",
    description: "Contacts page",
    component: isSSR() ? ContactsPage : lazy(() => import("../../pages/ContactsPage/ContactsPage"))
  },
  {
    path: "/:lang/feedback/",
    tag: "feedback",
    metaData: "metaDateReviews",
    title: "Feedback page",
    description: "Feedback page",
    component: isSSR() ? FeedbackPage : lazy(() => import("../../pages/FeedbackPage/FeedbackPage"))
  }
];
