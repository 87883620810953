import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { Button } from "../../../../../components/ui/Buttons/Button/Button";
import { ModalSelect } from "../../../../../components/ui/ModalSelect/ModalSelect";
import { errorToast, successToast } from "../../../../../utils/helpers/customToast.helper";
import { useTranslation } from "../../../../../hooks";
import { ApiService } from "../../../../../services/ApiService";

export const DropDown = ({ close }) => {
  const { lang } = useParams();
  const [searchParams] = useSearchParams();

  const socketHash = searchParams.get("order");

  const {
    orderProgressPage: { save, purposePlaceholder, goalSuccessSending, invalidGoal, proxyAlreadyEnabled }
  } = useTranslation();

  const methods = useForm({
    mode: "onSubmit"
  });

  useEffect(() => {
    const closeMenu = ({ target }) => {
      if (!target.closest(".goal-clarifying") && !target.closest(".modal__select__option")) {
        close();
      }
    };

    document.addEventListener("click", closeMenu);

    return () => document.removeEventListener("click", closeMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getErrorMessage = (error) => {
    const errorList = {
      "project_not_found": invalidGoal,
      "order_enabled": proxyAlreadyEnabled
    };

    const currentError = errorList[error] || "Something went wrong";

    return errorToast(currentError, lang);
  };

  const onSubmit = async (formData) => {
    try {
      const { asyncGoals: { value: projectCode } } = formData;

      const { data: { success, text: errorText }, status } = await ApiService.sendGoal({ socketHash, projectCode });

      if (status !== 200) {
        throw new Error();
      }

      if (success) {
        successToast(goalSuccessSending, lang);
        close();
        return;
      }

      getErrorMessage(errorText);

    } catch (e) {
      errorToast("Something went wrong", lang);
    }
  };

  return (
    <div className="goal-clarifying__menu">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="goal-clarifying__form"
          noValidate={true}
        >
          <ModalSelect
            name="asyncGoals"
            rules={{
              required: true
            }}
            placeholder={purposePlaceholder}
            isAsync
            isSelectValid
            /* eslint-disable-next-line no-empty-function */
            setOptions={() => {}}
          />
          <Button initClass="goal-clarifying__submit-button" tab="confirm">{save}</Button>

        </form>
      </FormProvider>
    </div>
  );
};
