import { useEffect, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MediaQuery from "react-responsive";
import { ModalCustom } from "../../../../../components/common/ModalCustom/ModalCustom";
import { ModalInput } from "../../../../../components/ui/ModalInput/ModalInput";
import { ModalSelect } from "../../../../../components/ui/ModalSelect/ModalSelect";

import { ApiService } from "../../../../../services/ApiService";

import { useCurrentPaymantSystems } from "../../../../../hooks/useCurrentPaymantSystems";
import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { Button } from "../../../../../components/ui/Buttons/Button/Button";
import { errorToast, warningToast } from "../../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../../components/ui/ButtonLoader/ButtonLoader";

export const Top = ({ controller }) => {
  // **Props
  const { lang } = useParams();

  // Form
  const methods = useForm();
  // **Redux State
  const {
    cabinet: { balance },
    formPlaceholders,
    notifications
  } = useTranslation();
  const { fiveDollarLogick } = useSelector((state) => state.content.proxies);

  // Dispatch
  const { openModal } = useDispatchedActions();

  // **Local State
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [minAmount] = useState(fiveDollarLogick?.enabled ? 5 : 0);
  const [paySysId, setPaySysId] = useState("");
  const [userBalance, setUserBalance] = useState({
    balance: 0,
    currencyCode: "",
    success: false
  });

  // **Hooks
  const currentPaymentSystems = useCurrentPaymantSystems();

  // Input params handling
  const inputHandler = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setAmount(e.target.value);
  };

  // select payment system
  const selectPaySys = (e) => {
    setPaySysId(e?.id);
  };

  // submit
  const submitHandler = async () => {
    if (+amount < +minAmount || !paySysId) {
      openModal();
      return;
    }

    setIsLoading(true);
    try {
      const { data, status } = await ApiService.createPaymentForBalance({
        paymentSystemId: paySysId,
        amount,
        locale: lang
      });

      if (status === 205) {
        throw notifications?.errorUserPaymentSystem;
      }

      if (status !== 200) {
        throw notifications?.error;
      }

      if (data?.errorCode === 2) {
        const message = notifications.minPriceForPaymentSystem + data?.errorMessage;
        warningToast(message, lang);
        setIsLoading(false);
        return;
      }

      if (data?.url) {
        window.location.href = data?.url;
      } else {
        errorToast(notifications?.error, lang);
        setIsLoading(false);
      }
    } catch (err) {
      errorToast(err?.message || err, lang);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ApiService.getBalance(controller)
      .then((req) => {
        if (req?.data?.success) {
          setUserBalance(req.data);
        }
      })
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="balance__top">
      <div className="balance__top__amount">
        <IconSvg tag="wallet" className="balance__top__amount-icon" />
        <div className="balance__top__amount-box">
          <div className="balance__top__amount-title"> {balance?.label}</div>
          <div className="balance__top__amount-data">
            {"$"}
            <span className="balance__top__amount-data__value">
              {userBalance?.balance}
            </span>
            {/* {userBalance?.currencyCode} */}
          </div>
        </div>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(submitHandler)}
          className="balance__top__form"
        >
          <div className="balance__top__form-actions">
            <ModalSelect
              name="payment"
              rules={{ required: true }}
              options={currentPaymentSystems.filter(
                (item) => item?.code !== "balance"
              )}
              placeholder={formPlaceholders?.paymentField}
              onChange={selectPaySys}
            />

            <ModalInput
              type={"number"}
              name={"amount"}
              placeholder={formPlaceholders?.paymentAmount}
              defaultValue={amount}
              handler={inputHandler}
              rules={{
                required: true
              }}
            />
            <MediaQuery maxWidth={992}> {fiveDollarLogick?.enabled && (
              <p className="balance__top__form-description">
                {balance?.description}
              </p>
            )}
</MediaQuery>

            <Button type="submit" tab="confirm" disabled={isLoading}>
              {isLoading ? <ButtonLoader /> : balance?.button}
            </Button>
          </div>
          <MediaQuery minWidth={993}> {fiveDollarLogick?.enabled && (
              <p className="balance__top__form-description">
                {balance?.description}
              </p>
            )}
</MediaQuery>
        </form>
      </FormProvider>

      <ModalCustom />
    </div>
  );
};
