import { useState } from "react";
import { useParams } from "react-router-dom";
import { getTimeAgo } from "../../../../utils/helpers";
import { useTranslation } from "../../../../hooks/useTranslation";
import { ChangePassword } from "./components/ChangePassword";
import { ChangeEmail } from "./components/ChangeEmail";
import "./Profile.scss";

export const Profile = ({ logout }) => {
  // *Props
  const { lang } = useParams();

  const { cabinet: { profile } } = useTranslation();

  // ** Local state
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);

  const handlerPass = () => setPasswordOpen((state) => !state);
  const handlerEmail = () => setEmailOpen((state) => !state);

  return (
    <div className="profile__container">
      <ul className="profile__data-list">
        <li className="profile__data-item">
          <div className="profile__data-item__title">
            {profile?.email?.title}
          </div>
          {emailOpen ? (
            <ChangeEmail close={handlerEmail} logout={logout} />
          ) : (
            <div className="profile__data-item__body">
              <p className="profile__data-item__info">
                <span className="text">{profile?.email?.info}</span>
                {JSON.parse(localStorage.getItem("client_profile"))?.email}
              </p>
              <button className="profile__data-item__action" onClick={handlerEmail}>
                {profile?.edit}
              </button>
            </div>
          )}
        </li>
        <li className="profile__data-item">
          <div className="profile__data-item__title">
            {profile?.password?.title}
          </div>

          {passwordOpen ? (
            <ChangePassword close={handlerPass} logout={logout} />
          ) : (
            <div className="profile__data-item__body">
              <p className="profile__data-item__info">
                <span className="text">{profile?.password?.info}</span>{" "}
                {getTimeAgo(lang, profile?.password)}
              </p>
              <button
                className="profile__data-item__action"
                onClick={handlerPass}
              >
                {profile?.edit}
              </button>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};
