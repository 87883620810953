import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { ButtonLoader } from "../../../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { useCurrentRentPeriod, useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import { Days } from "../../../../../../../MainPage/components/Prices/components/GoalItem/components/Days";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import "./AutoExtendingModal.scss";
import { useSetZIndex } from "../../../../../../../../hooks/useSetZIndex";

export const AutoExtendingModal = ({ handleConfirm, isLoading }) => {
  const {
    setIsExtendingModalOpen,
    setOrdersForUpdate,
    setOrderData
  } = useDispatchedActions();
  const { ordersForUpdate, selectedRows } = useSelector((state) => state.cabinet);
  const { activeData } = useSelector((state) => state.content);

  const [days, setDays] = useState();
  const [isConfirmation, setIsConfirmation] = useState(false);

  const rentPeriods = useCurrentRentPeriod(activeData?.proxy.code);

  const {
    buttons: { confirm },
    cabinet: { orders: { autoExtending: { modals: { confirm: {
      autoRenewal,
      selectedProxy,
      pcs,
      autoRenewalConfirm,
      cancelButton,
      autoRenewalButton,
      confirmationHintPart1,
      confirmationHintPart2
    } } } } }
  } = useTranslation();

  useSetZIndex(".cabinet", "999");

  const cancelHandler = () => {
    setOrderData({});
    setOrdersForUpdate([]);
    setIsExtendingModalOpen(false);
  };

  const showConfirmationModal = () => {
    setIsConfirmation(true);
  };

  const confirmAction = () => {
    if (isConfirmation) {
      handleConfirm(days?.days);

      return;
    }

    showConfirmationModal();
  };

  useEffect(() => {
    setDays(rentPeriods[0]);
  }, [rentPeriods]);

  return (
    <div className="confirm-extending">
      <div className="confirm-extending__container">
        { !isConfirmation ? (
          <>
            <div className="confirm-extending__title">{autoRenewal}</div>
            <div className="confirm-extending__chose-proxy">
              <span>{selectedProxy}</span> {ordersForUpdate.length || selectedRows.length} {pcs}.<br/>
              {autoRenewalConfirm}
            </div>
            <div className="confirm-extending__days">
              <Days
                proxyCode={activeData.proxy.code}
                setDays={setDays}
                days={days}
                rentPeriods={rentPeriods}
              />
            </div>
          </>
        ) : (
          <div className="confirm-extending__confirmation">
          <IconSvg tag="confirmIcon" />
          <div className="confirm-extending__hint">
            {`${confirmationHintPart1} ${days?.value} ${confirmationHintPart2}`}
          </div>
          </div>
        )}
        <div className="confirm-extending__actions">
          <Button
            onClick={cancelHandler}
          >
            {cancelButton}
          </Button>
          <Button
            tab="confirm"
            onClick={confirmAction}
            disabled={isLoading}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading
              ? <ButtonLoader />
              : isConfirmation ? confirm : autoRenewalButton}
          </Button>
        </div>
      </div>
    </div>
  );
};
