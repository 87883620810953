import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "../../../hooks";
import { classNames } from "../../../utils/helpers/classNames";
import "./QuantitySelect.scss";

export const QuantitySelect = ({ quantity, inputHandler, isLoading = false, customize }) => {
  const { formPlaceholders } = useTranslation();
  const [space, setSpace] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  const input = useRef();
  const inputField = useRef();
  const inputValue = useRef();

  useLayoutEffect(() => {
    if (inputValue.current || input.current) {
      const gap = 4;
      const padding = parseInt(window.getComputedStyle(input.current).paddingLeft);
      const { width } = inputValue.current.getBoundingClientRect();
      setSpace(width + padding + gap);
    }
  }, [quantity]);

  const handleFocus = () => {
    if (inputField.current) {
      inputField.current.focus();
    }
  };

  return (
    <div
      data-disabled={isLoading}
      style={customize}
      onClick={handleFocus}
      className={classNames(
        "quantity-select__input-box",
        { "quantity-select__input-box--focused": isFocused && !isLoading }
      )}
      ref={input}
    >
      <label className="quantity-select__input-box--label">
        {formPlaceholders?.quantity1}
      </label>
      <input
        ref={inputField}
        className="quantity-select__input-box--input"
        type="text"
        placeholder={formPlaceholders?.quantity1}
        onInput={inputHandler}
        value={quantity}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={isLoading}
      />
      {quantity && (
        <span
          style={{ left: `${space}px` }}
          className="quantity-select__input-box--quantity"
        >
            {formPlaceholders?.quantity2}
          </span>
      )}
      <span
        className="quantity-select__input-box--fake"
        ref={inputValue}>
        {quantity}
      </span>
    </div>
  );
};
