import { MobileProxyItem } from "./MobileProxyItem";

export const DesctopProxyList = ({ data, handlerClick }) => (
    <ul className="mobile-list">
      { data && data?.length > 0 ? data?.map?.((item) => (
        <li
          className="mobile-list__item"
          key={item?.id}
        >
          <MobileProxyItem data={item} handlerClick={handlerClick} />
        </li>
      )) : null}
    </ul>
  );
