import { subgoals } from "../../utils/constants/subgoals.constant";

export const getSubgoals = (code, fetchedSubgoals, lang) => {
  const options = [];

  if (!code) {
    return options;
  }

  const filteredSubgoals = fetchedSubgoals.filter((item) => item.code === code)[0].projects;

  filteredSubgoals.forEach((item) => {
    if (subgoals[lang].hasOwnProperty(item.code)) {
      const optionObject = {
        id: item.id,
        value: subgoals[lang][item.code],
        label: subgoals[lang][item.code],
        code: item.code
      };
      options.push(optionObject);
    }
  });

  const optionObject = {
    value: subgoals[lang].ownPurpose,
    label: subgoals[lang].ownPurpose,
    own: true
  };

  options.push(optionObject);

  return options;
};
