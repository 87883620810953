import { useState } from "react";
import "./GoalClarifying.scss";
import { useTranslation } from "../../../../hooks";
import { DropDown } from "./DropDown/DropDown";

export const GoalClarifying = () => {
  const {
    orderProgressPage: { goalСlarification }
  } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuHandler = () => {
    setIsMenuOpen((current) => !current);
  };

  const title = goalСlarification.split("_");

  return (
    <div className="goal-clarifying">
      <div className="goal-clarifying__title">
        {title[0]} <span onClick={menuHandler}>{title[1]}</span>
      </div>
      {isMenuOpen && <DropDown close={() => setIsMenuOpen(false)} />}
    </div>
  );
};
