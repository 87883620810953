import { CustomSelect } from "../../../../../../../components/ui/CustomSelect/CustomSelect";

import { useTranslation } from "../../../../../../../hooks";

export const Days = (props) => {
  // eslint-disable-next-line no-empty-function
  const { days, setDays, rentPeriods, isLoading, fetchPrice = () => {} } = props;

  const { formPlaceholders, main: { days: daysLabel } } = useTranslation();

   const handlerSetDays = (data) => {
     setDays(data);
     fetchPrice({ rentId: data.id });
   };

  return (
    <CustomSelect
      label={daysLabel}
      value={days}
      // className="index__select"
      // classNamePrefix="index__select"
      // isSearchable={false}
      changeFunc={handlerSetDays}
      options={rentPeriods}
      placeholder={formPlaceholders?.rentPeriod}
      disabled={isLoading}
    />
  );
};
