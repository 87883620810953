import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import "./Countries.scss";
import { useTranslation } from "../../../../../../../../hooks";
import { getCountries } from "../../../../../../../../utils/helpers";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { DropDown } from "./DropDown/DropDown";

export const Countries = ({ isOpen, setIsOpen, proxyCode, setCountryParams, setMobileOperators, setSelectedOperator }) => {
  const { lang } = useParams();
  const [localCountries, setLocalCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();

  const { countries, activeData } = useSelector((state) => state.content);
  const { mobileTariffs, isMobileTariffsLoaded } = useSelector((state) => state.mobileProxy);

  const { formPlaceholders: { country } } = useTranslation();

  const handleSelect = ({ target }) => {
    if (target.closest(".countries-select__dropdown")) {
      return;
    }

    setIsOpen((current) => !current);
  };

  useEffect(() => {
    if (proxyCode !== "MOBILE") {
      if (countries?.isLoaded) {
        const currentCountries = getCountries(countries?.data, lang, {
          showOnFrontType: proxyCode
        });
        if (currentCountries && currentCountries.length > 0) {
          const country = currentCountries.find(
            ({ id }) => id === activeData?.country?.id
          );
          if (country?.code) {
            setCountryParams({
              id: country?.id,
              code: country?.code
            });
            setSelectedCountry(country);
            // countryRef?.current?.select.setValue(country);
            if (country.code === "EUROPE") {
              setLocalCountries(
                currentCountries.filter(
                  (item) => item.isEurope && item.code !== "EUROPE"
                )
              );
            } else {
              setLocalCountries(
                currentCountries.filter((item) => item.code !== "EUROPE")
              );
            }
          } else {
            setLocalCountries(
              currentCountries.filter((item) => item.code !== "EUROPE")
            );
            // countryRef?.current?.select.setValue(formPlaceholders?.country);
            // setValue(formPlaceholders?.country);
            setSelectedCountry(null);
            setCountryParams({
              id: "",
              code: ""
            });
          }
        } else {
          setLocalCountries([]);
          setSelectedCountry(null);
          setCountryParams({
            id: "",
            code: ""
          });
          // countryRef?.current?.select.setValue(formPlaceholders?.country);
        }
      }
    } else if (isMobileTariffsLoaded && mobileTariffs[activeData?.currency?.id] && mobileTariffs[activeData?.currency?.id].length > 0) {
      setLocalCountries(
        getCountries(mobileTariffs[activeData?.currency?.id], lang, { countryCode: "EUROPE" })
      );
      // countryRef?.current?.select.setValue(formPlaceholders?.country);
      setSelectedCountry(null);
      setCountryParams({
        id: "",
        code: ""
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    proxyCode,
    activeData?.country?.id,
    activeData?.currency?.id,
    lang,
    isMobileTariffsLoaded
  ]);

  return (
    <div className="countries-select" onClick={handleSelect}>
      <div className={classNames(
        "countries-select__value",
        { "countries-select__value--country": !!selectedCountry }
      )}>
        <div className="countries-select__title">
          { !selectedCountry && (
            <IconSvg tag="planet" className="countries-select__icon" />
          )}
          {selectedCountry ? (
            <div className="countries-select__selected-country">
              {selectedCountry.label}
            </div>
          ) : (
            country
          )}
        </div>
        <IconSvg
          tag="triangleDown12"
          className={classNames(
            "countries-select__indicator",
            { "countries-select__indicator--open": isOpen }
          )}
        />
      </div>
      { isOpen && (
        <DropDown
          proxyCode={proxyCode}
          localCountries={localCountries}
          setCountryParams={setCountryParams}
          setMobileOperators={setMobileOperators}
          setSelectedCountry={setSelectedCountry}
          setIsOpen={setIsOpen}
          selectedCountry={selectedCountry}
          setSelectedOperator={setSelectedOperator}
        />
      )}
    </div>
  );
};
