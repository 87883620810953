import {useState} from "react";
import {useSelector} from "react-redux";
import {ApiService} from "../../../../../../../services/ApiService";
import {errorToast} from "../../../../../../../utils/helpers/customToast.helper";
import {CustomModal} from "../../../../../../../components/ui/CustomModal/CustomModal";
import {useDispatchedActions, useTranslation} from "../../../../../../../hooks";
import {CancelAutoExtendingModal} from "./CancelAutoExtendingModal";
import {NoCashModal} from "./NoCashModal";
import {AutoExtendingModal} from "./AutoExtendingModal";
import {useParams} from "react-router-dom";
import {sendRenewalWarning} from "../../../../../../../utils/helpers/sendRenewalWarning";

export const UpdateTableDataModal = () => {
  const { lang } = useParams();
  const {
    setOrdersForUpdate,
    updateAutoExtending,
    setIsExtendingModalOpen,
    setIsCancelExtendingModalOpen,
    setIsNoCashModalOpen,
    setOrderData,
    setSelectedRows
  } = useDispatchedActions();
  
  const { cabinet: { orders, orders: { renewalErrorMessages } } } = useTranslation();

  const { orderData, ordersForUpdate, selectedRows } = useSelector(state => state.cabinet);
  const { modals: {
    isExtendingModalOpen,
    isCancelExtendingModalOpen,
    isNoCashModalOpen,
  } } = useSelector(state => state.modal);
  const { activeData, countries } = useSelector((state) => state.content);
  
  
  const [isLoading, setIsLoading] = useState(false);
  
  const clearData = () => {
    setIsLoading(false);
    setOrderData({});
    setOrdersForUpdate([])
    setIsExtendingModalOpen(false);
    setIsCancelExtendingModalOpen(false)
  };
  
  const checkBalance = async (days) => {
    try {
      const { data, status } = await ApiService.checkIpForAutoExtending({
        ipIds: ordersForUpdate.length ? ordersForUpdate : selectedRows,
        proxyType: activeData.proxy.code,
        days,
      });
      
      if (status !== 200) {
        throw Error();
      }

      return data;
      
    } catch {
      errorToast("Something went wrong");
    }
  };
  
  const editTableData = async (days) => {
    try {
      const { status } = await ApiService.editUserTableData({
        ...orderData,
        autoExtendingDays: days,
      });
    
      if (status !== 200) {
        throw Error();
      }
    
      updateAutoExtending({
        ordersForUpdate,
        days
      });
    } catch (e) {
      errorToast("Something went wrong");
    } finally {
      setSelectedRows([]);
      clearData();
    }
  };
  
  const editSelectedTableData = async (days) => {
    try {
      const { data, status } = await ApiService.editSelectedUserTableData({
        ids: selectedRows,
        autoExtending: true,
        autoExtendingDays: days,
      });
  
      if (status !== 200) {
        throw Error();
      }
      
      setSelectedRows([]);
      updateAutoExtending({
        ordersForUpdate: data,
        days
      });
    } catch (e) {
      errorToast("Something went wrong");
    } finally {
      clearData();
    }
  };

  const handleConfirm = async (days) => {
    setIsLoading(true);
    
    if (days !== 0) {
      const { success, errorMessage, errorCode } = await checkBalance(days);
      
      if (!success && errorCode === 5) {
        clearData();
        setIsNoCashModalOpen(true);
        return
      }
  
      if (!success && errorCode) {
        sendRenewalWarning({
          code: errorCode,
          message: errorMessage,
          renewalErrorMessages,
          lang,
          countries: countries?.data,
        })

        clearData();
        return
      }
      
      if (!success) {
        clearData();
        return;
      }
    }
    
    orderData.id ? editTableData(days) : editSelectedTableData(days);
  };
  
  return (
    <>
      <CustomModal
        open={isNoCashModalOpen}
        onClose={() => setIsNoCashModalOpen(false)}
        styles={{ zIndex: "9999999"}}
      >
        <NoCashModal />
      </CustomModal>
      <CustomModal
        open={isExtendingModalOpen}
        onClose={() => setIsExtendingModalOpen(false)}
        styles={{ zIndex: "9999999"}}
      >
        <AutoExtendingModal
          isLoading={isLoading}
          handleConfirm={handleConfirm}
        />
      </CustomModal>
      <CustomModal
        open={isCancelExtendingModalOpen}
        onClose={() => setIsCancelExtendingModalOpen(false)}
        styles={{ zIndex: "9999999"}}
      >
        <CancelAutoExtendingModal
          isLoading={isLoading}
          handleConfirm={handleConfirm}
        />
      </CustomModal>
    </>
  );
};
