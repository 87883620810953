import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";
import { useDispatchedActions, useTranslation } from "../../../../../../../hooks";
import { AccessModal } from "../../../AccessModal/AccessModal";
import { errorToast, successToast } from "../../../../../../../utils/helpers/customToast.helper";
import { ApiService } from "../../../../../../../services/ApiService";
import { ReplacementModal } from "../../../Table/components/TableModals/ReplacementModal/ReplacementModal";
import { isSSR } from "../../../../../../../utils/helpers";
import { FormatModal } from "./Export/Export";

export const Actions = () => {
  const { lang } = useParams();
  const { notifications } = useTranslation();

  // **Local state
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);

  const { setIsExtendingModalOpen } = useDispatchedActions();
  const { selectedRows } = useSelector((state) => state.cabinet);

  const exportModalHandler = () => {
    setIsExportModalOpen((state) => !state);
  };

  const accessModalHandler = () => {
    setIsAccessModalOpen((state) => !state);
  };

  const extendAllSelected = () => {
    setIsExtendingModalOpen(true);
  };

  const ipReplacementModalHandler = () => {
    setIsReplacementModalOpen((state) => !state);
  };

  const copyForModernBrowsers = () => {
    if (typeof ClipboardItem && navigator.clipboard.write) {
      // eslint-disable-next-line no-undef
      const text = new ClipboardItem({
        "text/plain": ApiService.getAllSelectedIps(selectedRows)
          .then((res) => res.data.join("\n"))
            // eslint-disable-next-line no-undef
          .then((ips) => new Blob([ips], { type: "text/plain" }))
      });

      navigator.clipboard.write([text])
        .then(() => successToast(notifications?.copy, lang));
    } else {
      ApiService.getAllSelectedIps(selectedRows)
        .then((res) => res.data.join("\n"))
        .then((ips) => navigator.clipboard.writeText(ips))
        .then(() => successToast(notifications?.copy, lang));
    }
  };

  const copyForOldBrowsers = (textToCopy) => {
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;

    textarea.style.position = "absolute";
    textarea.style.left = "-99999999px";

    document.body.append(textarea);

    textarea.select();

    document.execCommand("copy");
    textarea.remove();
  };

  const getIpsForCopy = async () => {
    const { status, data } = await ApiService.getAllSelectedIps(selectedRows);

    if (status !== 200) {
      throw status;
    }
    return data.join("\n");
  };

  const handleCopySelected = async () => {
    if (isSSR()) {
      return;
    }

    try {
      if (navigator.clipboard && window.isSecureContext) {
        copyForModernBrowsers();

        return;
      }

      const ips = await getIpsForCopy();
      copyForOldBrowsers(ips);
      successToast(notifications?.copy, lang);
    } catch (e) {
      errorToast("Something went wrong");
    }
  };

  return (
    <div className="orders__actions">
      <ul className="orders__actions__list">
         <li>
          <button
            className="orders__actions__list-item"
            onClick={handleCopySelected}
            disabled={selectedRows.length === 0}
          >
            <IconSvg
              tag="orderCopy"
              className="orders__actions__list-item--copy"
            />
          </button>
        </li>
        <li>
          <button
            className="orders__actions__list-item"
            onClick={exportModalHandler}
            disabled={selectedRows.length === 0}
          >
            <IconSvg tag="orderExport" className="orders__actions__list-item--export" />
          </button>
        </li>
        <li>
          <button className="orders__actions__list-item" onClick={accessModalHandler}>
            <IconSvg tag="orderLock" className="orders__actions__list-item--lock" />
          </button>
        </li>
        <li>
          <button
            className="orders__actions__list-item"
            disabled={selectedRows.length === 0}
            onClick={ipReplacementModalHandler}
          >
            <IconSvg tag="orderUpdate" className="orders__actions__list-item--update" />
          </button>
        </li>
        <li>
          <button
            className="orders__actions__list-item"
            onClick={extendAllSelected}
            disabled={selectedRows.length === 0}
          >
            <IconSvg
              tag="orderCalendar"
              className="orders__actions__list-item--auto-extending"
            />
          </button>
        </li>
        {/*
        <li>
          <button className="orders__actions__list-item">
            <IconSvg tag="orderUpdate" />
          </button>
        </li>
        <li>
          <button className="orders__actions__list-item">
            <IconSvg tag="autoExtending" />
          </button>
        </li> */}
      </ul>
      {isExportModalOpen && (
        <FormatModal open={isExportModalOpen} close={exportModalHandler} />
      )}
      {isAccessModalOpen && (
        <AccessModal open={isAccessModalOpen} close={accessModalHandler} />
      )}
      {isReplacementModalOpen && (
        <ReplacementModal open={isReplacementModalOpen} close={ipReplacementModalHandler} />
      )}
    </div>
  );
};
