import { OrderIcon } from "../OrderIcon/OrderIcon";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import "./OrderStage.scss";
import { classNames } from "../../../../utils/helpers/classNames";
import { GoalClarifying } from "../GoalClarifying/GoalClarifying";
import { useTranslation } from "../../../../hooks";

export const OrderStage = (props) => {
  const { title, description, stageNum, iconTag } = props;

  const { isLoop, isProgress, percentage } = props;
  const { success, inProcess } = props;
  const { isError } = props;
  const { noProxy, noProject, forbiddenTarget, waitForClient } = props;

  const {
    orderProgressPage: { forbiddenTarget: forbiddenTargetLabel, noProxy: noProxyLabel, waitForClient: waitForClientLabel }
  } = useTranslation();

  const getDescription = () => {
    if (description && !noProject && !noProxy && !forbiddenTarget && !waitForClient) {
      return description;
    }

    if (noProxy) {
      return <span style={{ color: "#FF3649" }}>{noProxyLabel}</span>;
    }

    if (forbiddenTarget) {
      return <span style={{ color: "#FF3649" }}>{forbiddenTargetLabel}</span>;
    }

    if (waitForClient) {
      return <span style={{ color: "#FF3649" }}>{waitForClientLabel}</span>;
    }

    if (noProject) {
      return <GoalClarifying />;
    }
  };

  return (
    <div
      data-order={stageNum}
      className={classNames(
        "order-stage",
        {
          "order-stage--is-error": isError,
          "order-stage--is-waiting": !inProcess && !success
        }
      )}
    >
      <div className="order-stage__container">
        <div className="order-stage__about">
          <OrderIcon isError={isError} success={success} iconTag={iconTag} inProcess={inProcess} />
        </div>
        <div className="order-stage__info-container">
          <span className="order-stage__title">{title}</span>
          <div className="order-stage__description">{getDescription()}</div>
        </div>
      </div>
      <ProgressBar
        isError={isError}
        isLoop={isLoop}
        isProgress={isProgress}
        percentage={percentage}
        success={success}
      />
    </div>
  );
};
