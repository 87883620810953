import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Container } from "../../../../components/ui/Container/Container";
import { useTranslation } from "../../../../hooks/useTranslation";
import { FaqAccordion } from "../../../../components/common/Faq/FaqAccordion/FaqAccordion";
import { FaqTitle } from "../../../../components/common/Faq/FaqTitle/FaqTitle";
import "./FaqSection.scss";
import { PROXY_TYPES } from "../../../../utils/constants";
import { classNames } from "../../../../utils/helpers/classNames";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";

export const FaqSection = () => {
  // **Props
  const { lang } = useParams();
  const [searchParams] = useSearchParams();
  const s = searchParams.get("s");

  // ** Redux State
  const { content, activeData } = useSelector((state) => state.content);
  const { faq } = useTranslation();

  const isThemeDark = useIsThemeDark();

  // *Ref
  const refFaq = useRef(null);

  useEffect(() => {
    if (s === "faq" && refFaq?.current?.clientHeight) {
      refFaq.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [s]);

  const filteredItems = useMemo(() => {
    if (activeData?.proxy?.code === PROXY_TYPES.ipv4.code && !activeData?.country?.id) {
      return content?.data[lang]?.faq.filter((item) => item?.mainPage);
    }

    return content?.data[lang]?.faq
      .filter((item) => item?.proxyTypes?.includes(activeData?.proxy?.code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.proxy?.code, activeData?.country?.id, lang, content?.data[lang]?.faq]);

  return (
    content?.isLoaded && (
      <section
        ref={refFaq}
        className={classNames("faq", { "faq--dark": isThemeDark })}
        id="faq"
      >
        <Container>
          <div className="faq__container">
            <div className="faq__box-info">
              <FaqTitle />
              <p className="faq__description">
                {faq?.description}
              </p>
              <Link to={`/${lang}/contacts/`} className="faq__ask">{faq?.ask}</Link>
            </div>
            <FaqAccordion items={filteredItems} />
            {/* <MediaQuery minWidth={993}>
              <FaqAccordion items={content?.data[lang]?.faq} />
            </MediaQuery> */}
          </div>
        </Container>
        {/* <MediaQuery maxWidth={992}>
          <FaqAccordion items={content?.data[lang]?.faq} />
        </MediaQuery> */}
      </section>
    )
  );
};
