import {isSSR} from "./isSSR";

export const loadZohoChat = () => {
  if (isSSR()) {
    return;
  }
  
  const code = process.env.REACT_APP_ZOHO_SALESIQ_CODE;
  const chatId = "zsiqscript";
  const CHAT_WAIT_TIME_MINUTES = 20;
  const SECONDS_PER_MINUTE = 60;
  const waitTime = CHAT_WAIT_TIME_MINUTES * SECONDS_PER_MINUTE;
  
  let isAlreadyLoaded = document.querySelector(`#${chatId}`)
  
  if (isAlreadyLoaded) {
    return;
  }
  
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: code,
    values: {},
    ready: () => {
      window.$zoho.salesiq?.chat?.waittime(waitTime);
    }
  };
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = chatId;
  script.defer = true;
  script.src = "https://salesiq.zohopublic.eu/widget";
  
  const firstScriptEl = document.getElementsByTagName("script")[0];
  
  firstScriptEl.parentNode.insertBefore(script, firstScriptEl);
};
