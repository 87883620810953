import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiService } from "../../../services/ApiService";

export const fetchSiteContent = createAsyncThunk(
  "content/fetchSiteContent",
  // eslint-disable-next-line default-param-last
  async (locale = "ru", { rejectWithValue }) => {
    try {
      const response = await ApiService.getFullContent(locale);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return { data: response.data, locale };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const fetchSiteProxyParams = createAsyncThunk(
  "content/fetchSiteProxyRarams",
  // eslint-disable-next-line default-param-last
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getFrontConfig();

      if (response.response && response.response.status !== 200) {
        throw response;
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const fetchIsUserAuth = createAsyncThunk("content/fetchIsUserAuth", async (_, { rejectWithValue }) => {
  try {
    const response = await ApiService.isAuth();

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(false);
  }
});

export const fetchSiteSeo = createAsyncThunk("content/fetchSiteSeo", async ({ countryId, localeCode, proxyType }, { rejectWithValue }) => {
  try {
    const response = await ApiService.getSeoInfo({
      countryId,
      localeCode,
      proxyType
    });

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return { data: response.data, countryId, localeCode, proxyType };
  } catch (err) {
    return rejectWithValue(false);
  }
});

export const fetchAllCountries = createAsyncThunk("content/fetchAllCountries", async (_, { rejectWithValue }) => {
  try {
    const response = await ApiService.getAllCountries();

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(false);
  }
});

export const fetchRentPeriods = createAsyncThunk("content/fetchRentPeriods", async (_, { rejectWithValue }) => {
  try {
    const response = await ApiService.getRentPeriods();

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(false);
  }
});

export const fetchPaymentSystems = createAsyncThunk("content/fetchPaymentSystems", async (params, { rejectWithValue }) => {
  try {
    const response = await ApiService.getPaymentSystems(params);

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return { ...response.data, email: params?.email };
  } catch (err) {
    return rejectWithValue(false);
  }
});
