import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import { useDispatchedActions } from "../../../hooks/useDispatchedActions";

import { getLanguages } from "../../../utils/helpers/";

import "./Languages.scss";
import { classNames } from "../../../utils/helpers/classNames";

export const Languages = ({ menuDirection = "bottom" }) => {
  // **Props
  const { lang } = useParams();
  const navigate = useNavigate();
  const ref = useRef();

  // Dispatch
  const { changeLanguage, fetchSiteContent } = useDispatchedActions();

  // **State
  const [isOpen, setIsOpen] = useState(false);
  const { languages } = useSelector((state) => state.translation);
  const { content } = useSelector((state) => state.content);
  const localLanguages = getLanguages(languages).map((code) => ({
    label: code.code.toUpperCase(),
    value: code.code
  }));

  const changeLanguageHandler = (data) => {
    if (!data || data?.value === lang) {
      setIsOpen(false);
      return;
    }

    changeLanguage({ activeLang: data.value, navigate, lang });
    const keysContent = Object.keys(content?.data);

    if (!keysContent.includes(data.value)) {
      fetchSiteContent(data.value);
    }

    setIsOpen(false);
  };

  // const DropdownIndicator = (props) => (
  //   <components.DropdownIndicator {...props}>
  //     <IconSvg tag="triangleDown12" />
  //   </components.DropdownIndicator>
  // );

  useEffect(() => {
    if (ref.current) {
      const value = localLanguages.find((item) => item.value === lang);
      ref.current.select.setValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const debounce = (f, delay) => {
    let timerId;

    return (value) => {
      if (!value) {
        timerId = setTimeout(() => {
          f(false);
        }, delay);

        return;
      }

      clearTimeout(timerId);
      f(true);
    };
  };

  const handleMenu = debounce(setIsOpen, 300);

  return (
    <div
      className="language"
      onTouchStart={() => handleMenu(!isOpen)}
      onMouseEnter={() => handleMenu(true)}
      onMouseLeave={() => handleMenu(false)}
    >
      <div className="language__select">
        <div className="language__title">
          {lang.toUpperCase()}
        </div>
        <IconSvg
          tag="triangleDown12"
          className={classNames(
            "language__dropdown-indicator",
            { "language__dropdown-indicator--active": isOpen }
          )}
        />
      </div>
      {
      isOpen && (
          <div className={`language__dropdown language__dropdown--direction-${menuDirection}`}>
            <ul className="language__list">
              {localLanguages.map(({ label, value }) => (
                <li
                  className={classNames(
                    "language__item",
                    { "language__item--is-active": value === lang }
                  )}
                  key={value}
                  onClick={() => changeLanguageHandler({ value })}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
        )
      }
      {/*<Select*/}
      {/*  menuIsOpen={true}*/}
      {/*  ref={ref}*/}
      {/*  className={`${menuDirection ? menuDirection : ""} language__select`}*/}
      {/*  classNamePrefix="language__select"*/}
      {/*  // defaultMenuIsOpen={true}*/}
      {/*  isSearchable={false}*/}
      {/*  options={localLanguages}*/}
      {/*  openMenuOnClick={false}*/}
      {/*  openMenuOnFocus={true}*/}
      {/*  placeholder={""}*/}
      {/*  menuPlacement={*/}
      {/*    menuDirection && ["top", "bottom"].includes(menuDirection)*/}
      {/*      ? menuDirection*/}
      {/*      : "auto"*/}
      {/*  }*/}
      {/*  onChange={changeLanguageHandler}*/}
      {/*  components={{ DropdownIndicator }}*/}
      {/*/>*/}
    </div>
  );
};
