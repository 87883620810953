import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { classNames } from "../../../../../../../utils/helpers/classNames";
import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";
import "./NotificationCard.scss";
import { Divider } from "../../../../../../ui/Divider/Divider";
import { useTranslation } from "../../../../../../../hooks";

const formatDate = (isoDate, locale) => {
  const date = new Date(isoDate);

  const months = {
    "ru": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    "en": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  };

  const monthNames = months[locale];
  if (!monthNames) return "";

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const hours = date.getHours() < 10 ? `0${ date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${ date.getMinutes()}` : date.getMinutes();

  return `${month} ${day}, ${year} | ${hours}:${minutes}`;
};

export const NotificationCard = ({ isNotificationsOpen, notification: { title, body, id, read, date } }) => {
  const DEFAULT_CONTAINER_MAX_HEIGHT = 40;
  const TRANSITION_TIME_MS = 500;

  const { header: { notifications: { expand, collapse } } } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [expandButtonIsVisible, setExpandButtonIsVisible] = useState(true);
  const descriptionRef = useRef();
  const { lang } = useParams();

  useLayoutEffect(() => {
    const notificationsBody = document.getElementById(`data-notification-body-${id}`);

    if (notificationsBody?.scrollHeight <= DEFAULT_CONTAINER_MAX_HEIGHT) {
      setExpandButtonIsVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeCard = () => {
    const container = descriptionRef?.current;

    if (!container?.style) return;

    container.style.maxHeight = `${DEFAULT_CONTAINER_MAX_HEIGHT}px`;
    setIsOpen(false);
  };

  const handleCard = () => {
    const container = descriptionRef?.current;
    const contentHeight = container?.scrollHeight;

    if (!contentHeight || !container?.style) return;

    if (isOpen) {
      container.style.maxHeight = `${DEFAULT_CONTAINER_MAX_HEIGHT}px`;
    } else {
      container.style.maxHeight = `${contentHeight}px`;
    }

    setIsOpen((current) => !current);
  };

  useEffect(() => {
    if (!isNotificationsOpen) {
      setTimeout(closeCard, TRANSITION_TIME_MS);
    }
  }, [isNotificationsOpen]);

  return (
    <li className="user-notifications-card">
      <span className="user-notifications-card__date">{formatDate(date, lang)}</span>
      <div className={
        classNames(
          "user-notifications-card__title",
          { "user-notifications-card__title--new": !read }
        )}>
        {title}
      </div>
      <div
        ref={descriptionRef}
        className={classNames(
        "user-notifications-card__description"
      )}>
        <div id={`data-notification-body-${id}`} dangerouslySetInnerHTML={{ __html: body }}></div>
      </div>
      {expandButtonIsVisible && (
        <button
          className="user-notifications-card__button"
          onClick={handleCard}
        >
          <div className="user-notifications-card__button-label">
            { isOpen ? collapse : expand }
          </div>
          <IconSvg

            tag="arrow"
            className={classNames(
              "user-notifications-card__button-icon",
              { "user-notifications-card__button-icon--open": isOpen }
            )}
          />
        </button>
      )}
      <Divider styles={{ margin: "16px 0" }} />
    </li>
  );
};
