import { lazy } from "react";
import CabinetPage from "../../pages/CabinetPage/CabinetPage";
import { isSSR } from "../../utils/helpers";

export const CabinetRoutes = [
  {
    path: "/:lang/dashboard/",
    tag: "cabinet",
    metaData: "dashboard",
    title: "Cabinet page",
    description: "Cabinet page",
    component: isSSR() ? CabinetPage : lazy(() => import("../../pages/CabinetPage/CabinetPage"))
  },
  {
    path: "/:lang/payment/replenishment/",
    tag: "cabinet",
    metaData: "dashboard",
    title: "Cabinet page",
    description: "Cabinet page",
    component: isSSR() ? CabinetPage : lazy(() => import("../../pages/CabinetPage/CabinetPage"))
  }
];
