import { goals } from "../../utils/constants/goals.constant";

export const getGoals = (goalGroups, lang) => {
  const resultGoals = [];

  if (goalGroups && goalGroups.length > 0) {
    for (const goalGroup of goalGroups) {
      for (const goal of goals[lang]) {
        if (goal?.code === goalGroup?.code) {
          resultGoals.push(goal);
        }
      }
    }
  }

  return resultGoals;
};
