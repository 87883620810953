import {useLayoutEffect} from "react";

export const useSetZIndex = (selector, index) => {
  return useLayoutEffect(() => {
    const element = document.querySelector(selector);
    if (!element) return;
  
    element.style.zIndex = index;
  
    // eslint-disable-next-line no-return-assign
    return () => element.style.zIndex = "1";
  }, []);
};
