/* eslint-disable camelcase */
import { useRef, useState } from "react";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatchedActions } from "../../../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ApiService } from "../../../../../../../services/ApiService";
import { ModalInput } from "../../../../../../ui/ModalInput/ModalInput";
import { Button } from "../../../../../../ui/Buttons/Button/Button";
import { CustomModal } from "../../../../../../../components/ui/CustomModal/CustomModal";
import { passwordPattern } from "../../../../../../../utils/constants/passwordPattern";
import { authErrorsHandler } from "../../../../../../../utils/helpers/authErrorsHandler";
import {
  errorToast,
  successToast,
  warningToast
} from "../../../../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../../../ui/ButtonLoader/ButtonLoader";
import { emailPattern } from "../../../../../../../utils/constants/emailPattern";
import { AgreeWithRules } from "../../../../../agreeWithRules/AgreeWithRules";

export const RegisterModal = () => {
  // **Props
  const { lang } = useParams();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const reCaptchaRef = useRef();

  // Dispatch
  const { setLoginModal, setRegisterModal } = useDispatchedActions();

  // **State
  // const [useReCaptchaV3, setUseReCaptchaV3] = useState(true);
  // const [tokenV2, setTokenV2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreeWithConditions, setIsAgreeWithConditions] = useState(false);

  const { isCaptchaEnabled, content } = useSelector((state) => state.content);
  const { isRegisterModalOpened } = useSelector((state) => state.header);
  const {
    cabinet: { profile },
    header: { modalSingUp, formElem },
    notifications,
    main: {
      agreeWithRules: { warningMessage }
    }
  } = useTranslation();

  const methods = useForm({
    mode: "onChange"
  });
  const handleLogin = () => setLoginModal(true);

  const register = async (captchaToken) => {
    setIsLoading(true);
    const data = methods.getValues();
    try {
      const { status } = await ApiService.clientRegister({
        email: data.email,
        password: data.password,
        locale: lang,
        captchaToken: captchaToken ? captchaToken : "",
        captchaV2: true
      });

      if (status === 206) {
        return errorToast(notifications?.tempMail, lang);
      }

      if (status !== 200) {
        throw status;
      }
      successToast(modalSingUp.successMessage, lang);
      setLoginModal(true);
    } catch (status) {
      authErrorsHandler(status, "password", lang);
    } finally {
      setIsLoading(false);
      if (reCaptchaRef.current) {
        reCaptchaRef.current.reset();
      }
    }
  };

  const onSubmit = (data) => {
    if (!isAgreeWithConditions) {
      return warningToast(warningMessage, lang);
    }

    if (data.password !== data.rePassword) {
      return;
    }
    if (
      isCaptchaEnabled &&
      reCaptchaRef.current &&
      content?.data[lang]?.google_captcha_v2_public_key
    ) {
      reCaptchaRef.current.execute();
    } else {
      register();
    }
    // try {
    //   setIsPasswordTheSame(true);
    //   if (useReCaptchaV3) {
    //     let token = "";
    //     if (isCaptchaEnabled && executeRecaptcha) {
    //       token = await executeRecaptcha("e_commerce");
    //     }
    //     const { status } = await ApiService.clientRegister({
    //       email: data.email,
    //       password: data.password,
    //       locale: lang,
    //       captchaToken: token
    //     });
    //     if (status === 202) {
    //       setUseReCaptchaV3(false);

    //       return;
    //     }

    //     if (status !== 200) {
    //       throw status;
    //     }

    //     successToast(modalSingUp.successMessage);
    //     setLoginModal(true);
    //   } else {
    //     const { status } = await ApiService.clientRegister({
    //       email: data.email,
    //       password: data.password,
    //       locale: lang,
    //       captchaToken: isCaptchaEnabled ? tokenV2 : "",
    //       captchaV2: true
    //     });
    //     if (status !== 200) {
    //       throw status;
    //     }
    //     successToast(modalSingUp.successMessage);
    //     setLoginModal(true);
    //     setUseReCaptchaV3(true);
    //   }
    // } catch (status) {
    //   authErrorsHandler(status, "password", lang);
    // }
  };

  // const onChange = (value) => {
  //   setTokenV2(value);
  // };

  const handleClose = () => {
    setRegisterModal(!isRegisterModalOpened);
  };

  const { errors } = methods.formState;

  const isInvalidEmail = errors.email?.type === "pattern";

  const isPasswordTheSame =
    methods.getValues("password") === methods.getValues("rePassword");

  const isLessThanSixChars =
    errors.password?.type === "validate" ||
    errors.rePassword?.type === "validate";

  const isContainSpaces =
    errors.password?.type === "pattern" ||
    errors.rePassword?.type === "pattern";

  return (
    <CustomModal
      open={isRegisterModalOpened}
      onClose={handleClose}
      className="modal__login"
      header={modalSingUp.title}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="modal__login-form"
          noValidate={true}
        >
          <ModalInput
            label={formElem.email.label}
            // placeholder={formElem.email.placeholder}
            type="email"
            name="email"
            watch={methods.watch}
            rules={{
              required: true,
              pattern: emailPattern
            }}
          />
          {isInvalidEmail && (
            <p className="modal__form-wrong modal__login-hint">
              {formElem.invalidEmail}
            </p>
          )}
          <ModalInput
            label={formElem.password.label}
            // placeholder={formElem.password.placeholder}
            type="password"
            name="password"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 6,
              pattern: passwordPattern
            }}
          />
          <ModalInput
            label={formElem.repeatPassword.label}
            // placeholder={formElem.repeatPassword.placeholder}
            type="password"
            name="rePassword"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 6,
              pattern: passwordPattern
            }}
          />
          <div className="modal__login-password-errors">
            {!isPasswordTheSame && (
              <p className="modal__form-wrong modal__login-hint">
                {formElem.wrongPassword}
              </p>
            )}
            {isContainSpaces && (
              <p className="modal__form-wrong modal__login-hint">
                {profile?.wrongPasswordChars}
              </p>
            )}
            {isLessThanSixChars && (
              <p className="modal__form-wrong modal__login-hint">
                {profile?.wrongPasswordLength}
              </p>
            )}
          </div>
          <div className="modal__login-rules">
            <AgreeWithRules
              defaultValue={isAgreeWithConditions}
              handler={() => setIsAgreeWithConditions((prev) => !prev)}
            />
          </div>
          {isCaptchaEnabled &&
            content?.data[lang]?.google_captcha_v2_public_key && (
              // <div
              //   className={`modal__reCaptchaV2 modal__reCaptchaV2${
              //     useReCaptchaV3 ? "-display-none" : "-display-block"
              //   }`}
              // >
              <ReCAPTCHA
                ref={reCaptchaRef}
                // theme="dark"
                size="invisible"
                sitekey={content?.data[lang]?.google_captcha_v2_public_key}
                onChange={register}
              />
              // </div>
            )}

          <Button type="submit" disabled={isLoading}>
            {isLoading ? <ButtonLoader /> : modalSingUp.singUpBtn}
          </Button>
        </form>
      </FormProvider>
      <div className="modal__login-footer">
        <span> {modalSingUp.textLogin}</span>
        <Button onClick={handleLogin} initClass="modal__action">
          {modalSingUp.loginBtn}
        </Button>
      </div>
    </CustomModal>
  );

  //   if (isRegisterModalOpened) {
  //     return (
  //       <div id="owerlay" className="modal-owerlay" onClick={handleClose}>
  //         <div className="modal-container modal__login">
  //           <div className="modal__top">{modalSingUp.title}</div>

  // <FormProvider {...methods}>
  //   <form onSubmit={methods.handleSubmit(onSubmit)} className="modal__login-form">
  //     <ModalInput
  //       label={formElem.email.label}
  //       placeholder={formElem.email.placeholder}
  //       type="email"
  //       name="email"
  //     />
  //     <ModalInput
  //       label={formElem.password.label}
  //       placeholder={formElem.password.placeholder}
  //       type="password"
  //       name="password"
  //     />
  //     <ModalInput
  //       label={formElem.repeatPassword.label}
  //       placeholder={formElem.repeatPassword.placeholder}
  //       type="password"
  //       name="rePassword"
  //     />
  //     {!isPasswordTheSame && (
  //       <p className="modal__form-wrong">{formElem.wrongPassword}</p>
  //     )}
  //     {isCaptchaEnabled && (
  //       <div
  //         className={`modal__reCaptchaV2 modal__reCaptchaV2${
  //           useReCaptchaV3 ? "-display-none" : "-display-block"
  //         }`}
  //       >
  //         <ReCAPTCHA
  //           sitekey={content?.data[lang]?.google_captcha_v2_public_key}
  //           onChange={onChange}
  //         />
  //       </div>
  //     )}
  //     <Button
  //       type="submit"
  //       disabled={!useReCaptchaV3 && !tokenV2}
  //     >
  //       {modalSingUp.singUpBtn}
  //     </Button>
  //   </form>
  // </FormProvider>
  // <div className="modal__login-footer">
  //   <span> {modalSingUp.textLogin}</span>
  //   <button onClick={() => setLoginModal(true)} className="modal__action">
  //     {modalSingUp.loginBtn}
  //   </button>
  // </div>
  // <button
  //   className="modal-container-close_btn"
  //   onClick={() => setRegisterModal(!isRegisterModalOpened)}
  // >
  //   <IconSvg tag="close" />
  // </button>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
};
