import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorToast } from "../../../../../../../../utils/helpers/customToast.helper";
import { CustomSelect } from "../../../../../../../../components/ui/CustomSelect/CustomSelect";
import { PROXY_TYPES } from "../../../../../../../../utils/constants";
import { CustomModal } from "../../../../../../../../components/ui/CustomModal/CustomModal";
// import { useDispatchedActions } from "../../../../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { ApiService } from "../../../../../../../../services/ApiService";

import "./Export.scss";
import { ButtonLoader } from "../../../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { useSetZIndex } from "../../../../../../../../hooks/useSetZIndex";

const EXTENSION = [
  { value: "txt", label: "TXT" },
  { value: "csv", label: "CSV" }
];

// const OPTIONS = {
//   IPv4: [{ value: "%ip%:%port_http%:%login%:%password%", label: "%ip%:%port_http%:%login%:%password%" }],
//   IPv6: [{ lalue: "%ip%:%port%:%login%:%password%", label: "%ip%:%port%:%login%:%password%" }],
//   MOBILE: [{ lalue: "%ip%:%port_http%:%login%:%password%", label: "%ip%:%port_http%:%login%:%password%" }]
// };

export const FormatModal = ({ open, close }) => {
  const { lang } = useParams();
  // **Redux State
  const { orders, format,
    notifications } = useTranslation().cabinet;
  const { activeData } = useSelector((state) => state.content);

  // Dispatch
  // const { setExportDividers, setExportSelectors } = useDispatchedActions();

  // **Local state
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [active, setActive] = useState("FILE");
  // const [localType, setLocalType] = useState(protocols[0]);
  const [localExtension, setLocalExtansion] = useState(EXTENSION[0]);
  // const [localOptions, setLocaloptions] = useState(OPTIONS[activeData?.proxy?.code]);
  // const [localOption, setLocaloption] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useSetZIndex(".cabinet", "999");

  // constant
  const defaultValues = {
    IPv4: [{ value: "%ip%:%port_http%:%login%:%password%", label: "%ip%:%port_http%:%login%:%password%" }],
    IPv6: [{ lalue: "%ip%:%port%:%login%:%password%", label: "%ip%:%port%:%login%:%password%" }],
    MOBILE: [{ lalue: "%ip%:%port_http%:%login%:%password%", label: "%ip%:%port_http%:%login%:%password%" }]
  };
 // **Redux State

  const { selectedRows } = useSelector((state) => state.cabinet);

  const getFormattedData = (ips, divider) => ips.map((ip) => (
      `${ip.innerIp}${divider}${ip.proxyType === PROXY_TYPES.ipv6.code ? ip.port : ip.proxyPort}${divider}${ip.authLogin}${divider}${ip.authPassword}`
    )).join("\n");

  const onClose = () => {
    setLocalExtansion(EXTENSION[0]);
    close();
  };

  const downloadData = (txtFormatData, csvFormatData) => {
    const a = document.createElement("a");

    if (localExtension.value === "txt") {
      a.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(txtFormatData)}`);

      a.setAttribute("download", "proxies.txt");
    } else if (localExtension.value === "csv") {
      a.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(csvFormatData)}`);

      a.setAttribute("download", "proxies.csv");
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

const downloadHandler = async () => {
  try {
    setIsLoading(true);
    const { status, data } = await ApiService.getSelectedClientIps(selectedRows);

    if (status !== 200) {
      throw status;
    }

    const txtFormatData = getFormattedData(data, ":");
    const csvFormatData = getFormattedData(data, ";");

    downloadData(txtFormatData, csvFormatData);

  } catch (err) {
    errorToast(notifications?.error, lang);
  } finally {
    setIsLoading(false);
  }
};

  // const inputHandler = (e) => {
  //   setInputValue(e.target.value);
  // };

  // const changePortHandler = (type) => {
  //   setInputValue(`${inputValue}%${type}%`);
  // };

  // const onCloseModalHandler = () => {
  //   close();
  //
  //   let splittedArray = inputValue
  //     .split("%")
  //     .filter((string) =>
  //       /^(ip|port_http|port_socks|port|protocol|login|password)$/g.test(string)
  //     );
  //
  //   // Define dividers
  //   const dividers = inputValue
  //     .replace(
  //       /%ip%|%port_http%|%port_socks%|%port%|%protocol%|%login%|%password%/g,
  //       ""
  //     )
  //     .split("");
  //
  //   setExportDividers(dividers);
  //
  //   const selectorsObject = {
  //     ip: "ip",
  //     innerIp: "innerIp",
  //     // eslint-disable-next-line camelcase
  //     port_http: "proxyPort",
  //     // eslint-disable-next-line camelcase
  //     port_socks: "socksPort",
  //     login: "authLogin",
  //     password: "authPassword",
  //     port: "port",
  //     protocol: "protocol"
  //   };
  //
  //   splittedArray = splittedArray.map((string) => {
  //     if (string === "ip" && activeData?.proxy?.code === "IPv6") {
  //       return selectorsObject.innerIp;
  //     } else {
  //       return selectorsObject[string];
  //     }
  //   });
  //
  //   setExportSelectors(splittedArray);
  // };

// const handlerSetActiveProxy = (item) => setActive(item.code);

  useEffect(() => {
    setInputValue(defaultValues[activeData?.proxy.code]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.proxy?.code]);
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      styles={{ zIndex: "9999999" }}
      className="export"
      header={
        <>
          {format?.title}
          {/* <GroupButtons
            list={[
              { code: "FILE", body: "File" },
              { code: "LINK", body: "Link" }
            ]}
            active={active}
            handlerClick={handlerSetActiveProxy}
          /> */}
        </>
      }
    >
      {/* {protocols && protocols.length > 0 && <CustomSelect
        label={"label"}
        options={protocols}
        defaultValue={protocols[0]}
        placeholder={""}
        changeFunc={setLocalType}
      />} */}

      {/* <CustomSelect
        label={"label"}
        options={localOptions}
        defaultValue={localOptions[0]}
        placeholder={""}
        changeFunc={(option) => setLocalType(option)}
      /> */}
      {EXTENSION && EXTENSION.length > 0 && <CustomSelect
        label={orders?.slectFileType}
        options={EXTENSION}
        defaultValue={EXTENSION[0]}
        placeholder={""}
        changeFunc={setLocalExtansion}
      />}

      <div className="export__actions">
        <Button
          className="export__cansel"
          onClick={close}
        >
          {orders?.cancel}
        </Button>
        <Button
          tab="confirm"
          onClick={downloadHandler}
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader /> : orders?.download }
        </Button>
      </div>
    </CustomModal>
  );
  //     // <Modal
  //     //   open={open}
  //     //   onClose={onCloseModalHandler}
  //     //   closeOnEsc
  //     //   closeOnOverlayClick
  //     //   center
  //     //   classNames={{
  //     //     modal: "modal__form modal__form--cabinet"
  //     //   }}
  //     // >
  //     //   <div className="modal__top">{format?.title}</div>
  //     //   <div className="modal__body">
  //     //     <div className="modal__input">
  //     //       <input value={inputValue} onInput={inputHandler} />
  //     //     </div>
  //     //     <div className="modal__vars">
  //     //       <p>
  //     //         {format?.variables}{" "}
  //     //         <span onClick={() => changePortHandler("ip")}>ip</span>,{" "}
  //     //         {activeData?.proxy?.code === "IPv6" ? (
  //     //           <>
  //     //             <span onClick={() => changePortHandler("port")}>port</span>,{" "}
  //     //             <span onClick={() => changePortHandler("protocol")}>
  //     //               protocol
  //     //             </span>
  //     //             ,
  //     //           </>
  //     //         ) : (
  //     //           <>
  //     //             <span onClick={() => changePortHandler("port_http")}>
  //     //               port_http
  //     //             </span>
  //     //             ,{" "}
  //     //             <span onClick={() => changePortHandler("port_socks")}>
  //     //               port_socks
  //     //             </span>
  //     //             ,
  //     //           </>
  //     //         )}
  //     //         <span onClick={() => changePortHandler("login")}>login</span>,{" "}
  //     //         <span onClick={() => changePortHandler("password")}>
  //     //           password
  //     //         </span>{" "}
  //     //         {format?.textClicking}
  //     //       </p>
  //     //       <p>
  //     //         {format?.example} {inputValue}
  //     //       </p>
  //     //     </div>
  //     //     <Button
  //     //       position="center"
  //     //       size="small"
  //     //       type="black"
  //     //       onClick={onCloseModalHandler}
  //     //     >
  //     //       {table?.applyBtn}
  //     //     </Button>
  //     //   </div>
  //     // </Modal>

};
