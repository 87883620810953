import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { classNames } from "../../../../../utils/helpers/classNames";
import { useTranslation } from "../../../../../hooks";
import { DropDown } from "./components/DropDown/DropDown";
import { MobileDropDown } from "./components/MobileDropDown/MobileDropDown";
import "./Solutions.scss";

export const Solutions = ({ onClose }) => {
  const { lang } = useParams();
  const { header: { solutions: { solutionsNav, tgBot, addonsTitle } } } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const addons = [
    { name: "Google Chrome", link: "https://chromewebstore.google.com/detail/proxy-ipv4/phgomncepnbjgkonbkhbggblkcfkknhe?hl=uk&utm_source=ext_sidebar" }
    // { name: "Mozilla Firefox", link: "#" },
    // { name: "Opera", link: "#" },
    // { name: "Safari", link: "#" },
  ];

  // const handler = (event) => {
  //   event.stopPropagation();
  //   setIsOpen((current) => !current);
  // };

  const closeSelect = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsOpen(false);
    onClose();
  };

  const debounce = (f, delay) => {
    let timerId;

    return (value) => {
      if (!value) {
        timerId = setTimeout(() => {
          f(false);
        }, delay);

        return;
      }

      clearTimeout(timerId);
      f(true);
    };
  };

  const handleMenu = debounce(setIsOpen, 300);

  const handleMenuOnTouch = ({ target }) => {
    if (target.classList.contains("solutions__title")) {
      setIsOpen((current) => !current);
    }
  };

  const solutionsList = (
    <>
      <ul className="solutions__list">
        <li className="solutions__item">
          <a
            href="https://t.me/IPv4TelegramBot"
            className="solutions__dropdown-item solutions__telegram-bot"
            target="_blank"
            rel="noreferrer"
            onClick={closeSelect}
          >
            <IconSvg tag="robot" className="solutions__item-icon" />
            {tgBot}
          </a>
        </li>
        <li>
          <Link
            to={`/${lang}/documentation-api/`}
            className="solutions__dropdown-item solutions__api"
            onClick={closeSelect}
          >
            <IconSvg tag="codeIcon" className="solutions__item-icon" />
            API
          </Link>
        </li>
      </ul>
      <div className="solutions__addons-title">
        {addonsTitle}
      </div>
      <ul className="solutions__addons-list">
        {addons.map((addon) => (
          <li className="solutions__item" key={addon.name}>
            <a
              href={addon.link}
              rel="noreferrer"
              className="solutions__dropdown-item solutions__chrome"
              onClick={closeSelect}
              target="_blank"
            >
              <IconSvg tag="chromeExt" className="solutions__icon-chrome" />
              <div className="solutions__chrome-title">{addon.name}</div>
            </a>
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <div
      className="solutions"
      onTouchStart={handleMenuOnTouch}
      onMouseEnter={() => handleMenu(true)}
      onMouseLeave={() => handleMenu(false)}
    >
      <div className="solutions__select">
        <div className="solutions__title">
          {solutionsNav}
        </div>
        <IconSvg
          tag="triangleDown12"
          className={classNames(
            "solutions__dropdown-indicator",
            { "solutions__dropdown-indicator--active": isOpen }
          )}
        />
      </div>
      <div className="media__max-width-992">
        <MobileDropDown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        >
          {solutionsList}
        </MobileDropDown>
      </div>
      <div className="media__min-width-993">
        {
          isOpen && (
            <DropDown
              isOpen={isOpen}
              closeSelect={closeSelect}
            >
              {solutionsList}
            </DropDown>
          )
        }
      </div>
    </div>
  );
};
