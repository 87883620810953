import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "../../../../hooks";

export const DisplayPrice = (props) => {
  // **Props
  const { amount, quantity, prefixClassName } = props;
  // **Redux State

  const { activeData } = useSelector((state) => state.content);
  const { formPlaceholders } = useTranslation();
  // **Local State
  const [displayPrices, setDisplayPrices] = useState(0);

const currentClassNames = (name) => `${typeof prefixClassName === "string" ? `${prefixClassName}__` : ""}${name}`;

  // display prices logical
  useEffect(() => {
    if (+amount && +quantity) {
      let price = +(amount / +quantity).toFixed(2);
      if (price === 0) {
        price = +(amount / +quantity).toFixed(3);
      }
      setDisplayPrices(price);
    } else {
      setDisplayPrices(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  return (
    <div className={currentClassNames("price")} >
      <div className={currentClassNames("price--full")} >
        <span className={currentClassNames("price--amount")}>{amount}</span>
        <span className={currentClassNames("price--currency")}>{activeData?.currency?.value}</span>
      </div>
      <div className={currentClassNames("price--piece")} >
        <span className={currentClassNames("price--amount")} >{displayPrices}</span>
        <span className={currentClassNames("price--currency")}>
          {activeData?.currency?.value}/{formPlaceholders?.quantity2}.
        </span>

      </div>
    </div>
  );
};
