import MediaQuery from "react-responsive";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaqTitle } from "../../../../components/common/Faq/FaqTitle/FaqTitle";
import { FaqAccordion } from "../../../../components/common/Faq/FaqAccordion/FaqAccordion";
import { ApiService } from "../../../../services/ApiService";
import { errorToast } from "../../../../utils/helpers/customToast.helper";
import { FaqSlider } from "./components/FaqSlider/FaqSlider";
import { FaqInfo } from "./components/FaqInfo/FaqInfo";
import "./FaqTab.scss";

export const FaqTab = () => {
  const { lang } = useParams();

  const [faqThemes, setFaqThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState("");

  useEffect(() => {
    (async function() {

      try {
        const { status, data } = await ApiService.getFAQ(lang);

        if (status !== 200) {
          throw status;
        }
        setFaqThemes(data);
        setSelectedTheme(data[0].faqTypeName);
      } catch (status) {
        errorToast("Something went wrong");
      }
    })();
  }, [lang]);

  const getQuestions = () => {
    if (!selectedTheme) {
      return;
    }

    const currentFAQTheme = faqThemes.find((theme) => theme.faqTypeName === selectedTheme);
    return currentFAQTheme?.faq;
  };

  return (
    <div className="faq-tab">
      <FaqTitle />
      <MediaQuery maxWidth={992}>
        <div className="faq-tab__info">
          <FaqInfo />
        </div>
      </MediaQuery>
      <div className="faq-tab__slider">
        <FaqSlider
          faqThemes={faqThemes}
          setSelectedTheme={setSelectedTheme}
          selectedTheme={selectedTheme}
        />
      </div>
      <div className="faq-tab__container">
        <MediaQuery minWidth={993}>
          <div className="faq-tab__info">
            <FaqInfo />
          </div>
        </MediaQuery>
        <div className="faq-tab__accordion">
          <FaqAccordion items={getQuestions()} />
        </div>
      </div>
    </div>
  );
};
