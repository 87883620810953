import { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";

import { Loading } from "../../ui/Loading/Loading";

import { ApiService } from "../../../services/ApiService";

export const WebMoney = () => {
  const { paymentId } = useParams();
  const [params, setParams] = useState(null);
  const [isParamsLoaded, setIsParamsLoaded] = useState(false);

  const formBtn = useRef(null);

  useEffect(() => {
    ApiService.webmoneyInfo(paymentId).then((req) => {
      if (req.data) {
        setParams(req.data);
        setIsParamsLoaded(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId]);

  // отправка данных для оплаты
  useEffect(() => {
    if (params && formBtn && isParamsLoaded) {
      formBtn.current.click();
    }
  }, [params, isParamsLoaded]);

  return (
    <>
      {isParamsLoaded && (
        <form
          id="pay"
          name="pay"
          method="POST"
          action={params?.url}
          acceptCharset="UTF-8"
          style={{ opacity: 0, visibility: "hidden" }}
        >
          <input name="LMI_PAYMENT_AMOUNT" value={params?.amount} />
          <input name="LMI_PAYMENT_DESC" value={params?.description} />
          <input name="LMI_PAYMER_EMAIL" value={params?.email} />
          <input name="LMI_PAYMENT_NO" value={params?.paymentId} />
          <input name="LMI_PAYEE_PURSE" value={params?.walletId} />
          <input name="LMI_SUCCESS_METHOD" value={params?.successMethod} />
          <input name="LMI_FAIL_METHOD" value={params?.failMethod} />
          <input name="LMI_PAYER_COUNTRYID" value={params?.countryId} />
          <input name="LMI_PAYER_PCOUNTRYID" value={params?.pCountryId} />
          <input name="LMI_LANG" value={params?.localeCode} />
          <input name="LMI_SIM_MODE" value="0" />
          <input name="LMI_SUCCESS_URL" value={`${params?.successUrl}`} />
          <input name="LMI_SUCCESS_METHOD" value="1" />
          <input name="LMI_FAIL_URL" value={`${params?.cancelUrl}`} />
          <input name="LMI_FAIL_METHOD" value="1" />

          <button ref={formBtn} type="submit" value="submit">
            submit
          </button>
        </form>
      )}
      <Loading fixed />z
    </>
  );
};
