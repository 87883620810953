/* eslint-disable camelcase */
import { useSelector } from "react-redux";
import { forwardRef } from "react";
import Select, { components } from "react-select";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";

import "./CustomSelect.scss";
import { getBorderColor } from "../../../utils/helpers/getBorderColor";

export const CustomSelect = forwardRef((props, ref) => {
  // **Props
  const { options, placeholder, changeFunc, defaultValue, label, value, disabled = false, ...rest } = props;

  const { skin } = useSelector((state) => state.header);

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconSvg tag="triangleDown12" />
    </components.DropdownIndicator>
  );

  const handlerChange = (value) => changeFunc?.(value);

  return (
    <div className="custom__select__container">
      {label && <div className="custom__select__label">{label}</div>}
      <Select
        // {...field}
        className="custom__select"
        ref={ref}
        value={value}
        isSearchable={false}
        classNamePrefix="custom__select"
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handlerChange}
        components={{ DropdownIndicator }}
        isDisabled={disabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: getBorderColor(state, skin)
          })
        }}
        {...rest}
        //  menuPortalTarget={document.querySelector("body")}
      />
    </div>
  );
});
