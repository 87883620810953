import { useMatch } from "react-router-dom";
import { MobileProxyReducerActions } from "../../redux/reducers/MobileProxyReducer/MobileProxy.reducer";
import { ContentReducerActions } from "../../redux/reducers/ContentReducer/Content.reducer";
import { HeaderReducerActions } from "../../redux/reducers/HeaderReducer/Header.reducer";
import { ApiService } from "../../services/ApiService";
import { Seo } from "./components/Seo/Seo";
import { FaqSection } from "./components/FaqSection/FaqSection";
import { Feedback } from "./components/Feedback/Feedback";
import { Index } from "./components/Index/Index";
import { Prices } from "./components/Prices/Prices";
import { Tips } from "./components/Tips/Tips";
import { ProxyManadger } from "./components/ProxyManadger/ProxyManadger";
import { OurClients } from "./components/OurClients/OurClients";
import { Benefits } from "./components/Benefits/Benefits";

const MainPage = () => {
  const matchIPv4 = useMatch("/:lang");

  return (
    <>
      <Index />
      <Tips />
      <Prices />
      {matchIPv4 ? <Benefits /> : <ProxyManadger />}
      <Feedback />
      <OurClients />
      <FaqSection />
      <Seo />
    </>
  );
};

MainPage.getServerSideState = async (store, params, baseURL) => {
  const content = await ApiService.getFullContentSSR(baseURL, params.locale);

  store.dispatch(
    HeaderReducerActions.setDarckTheme(params.theme)
  );

  store.dispatch(
    ContentReducerActions.setSiteContentSSR({
      data: content.data,
      locale: params.locale
    })
  );

  const proxyParams = await ApiService.getFrontConfigSSR(baseURL);
  store.dispatch(ContentReducerActions.setSiteProxyParamsSSR(proxyParams.data));

  const tempTypeProxy = store
    .getState()
    .content.proxies?.enabled.find((item) => item?.code === params.proxy);

  if (tempTypeProxy?.code) {
    store.dispatch(ContentReducerActions.setActiveProxy(tempTypeProxy));
  }

  const currencyId = store.getState().content?.activeData?.currency?.id;

  if (currencyId) {
    const mobileTariff = await ApiService.getMobileProxySSR(baseURL, currencyId);
    store.dispatch(
      MobileProxyReducerActions.setMobileTariffsSSR({
        data: mobileTariff.data,
        currencyId
      })
    );
  }
  // const auth = await ApiService.isAuth();
  const countries = await ApiService.getAllCountriesSSR(baseURL);
  const rentPeriod = await ApiService.getRentPeriodsSSR(baseURL);
  // const paymetSystems = await ApiService.getPaymentSystems({ email: "", fingerprint: "" });

  const country = countries.data.find((el) => el.url === params.country) || "";
  if (country) {
    store.dispatch(
      ContentReducerActions.setActiveCountry({
        id: country?.id,
        code: country?.code
      })
    );
  } else {
    const tempCountry = countries.data.find((item) =>
      item.showOnFrontType.includes(params.proxy)
    );
    if (tempCountry && params.proxy !== "MOBILE") {
      store.dispatch(
        ContentReducerActions.setActiveCountry({
          id: tempCountry?.id,
          code: tempCountry?.code
        })
      );
    } else {
      store.dispatch(
        ContentReducerActions.setActiveCountry({ id: "", code: "" })
      );
    }
  }
  const seoInfo = await ApiService.getSeoInfoSSR(baseURL, {
    countryId: country?.id,
    localeCode: params.locale,
    proxyType: params.proxy
  });

  store.dispatch(ContentReducerActions.setUserAuthData({ isAuth: false, email: "" }));
  store.dispatch(ContentReducerActions.setAllCountriesSSR(countries.data));
  store.dispatch(ContentReducerActions.setRentPeriodsSSR(rentPeriod.data));
  // store.dispatch(ContentReducerActions.setPaymentSystemsSSR(paymetSystems.data));
  store.dispatch(
    ContentReducerActions.setActiveCountry({
      id: country?.id,
      code: country?.code
    })
  );

  store.dispatch(
    ContentReducerActions.setSiteSeoSSR({
      data: seoInfo.data,
      countryId: country?.id,
      localeCode: params.locale,
      proxyType: params.proxy
    })
  );
};

export default MainPage;
