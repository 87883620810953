import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "../../hooks";
import { Container } from "../../components/ui/Container/Container";
import { webStompClient } from "../../utils/StompClient";
import { Loading } from "../../components/ui/Loading/Loading";
import { ReviewsList } from "./components/ReviewsList/ReviewsList";
import { OrderStagesList } from "./components/OrderStagesList/OrderStagesList";
import { OrderDataRemoved } from "./components/OrderDataRemoved/OrderDataRemoved";
import "./OrderProgressPage.scss";

export const OrderProgressPage = () => {
  // const { lang } = useParams();
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stompClient = webStompClient();

  // const { setLoginModal } = useDispatchedActions();
  // const { user } = useSelector((state) => state.content);

  const [orderProgressNotification, setOrderProgressNotification] = useState(null);
  const [orderProgressStage, setOrderProgressStage] = useState({ stages: {} });

  const { paymentSuccessPage: { thanksForOrder, orderMessage } } = useTranslation();

  const invokeSubscribe = () => {
    const orderHash = searchParams.get("order") || null;

    // if (!orderHash) {
    //   orderHash = "000000000000000000";
    // }

    const notificationSubscribe = stompClient.subscribe(
      `/ws/api/progress/v1/${orderHash}`,
      (message) => {
        const newVar = { ...JSON.parse(message?.body) };
        // eslint-disable-next-line no-console
        // console.log("body", newVar);
        if (message?.body) {
          setOrderProgressStage({ ...newVar });
        } else {
          setOrderProgressStage({});
        }
      }
    );
    setOrderProgressNotification(notificationSubscribe);
  };

  useEffect(
    () => {
      // eslint-disable-next-line no-undef
      const intervalCounter = setInterval(() => {
        if (stompClient && stompClient.connected === true) {
          if (orderProgressNotification === null) {
            // eslint-disable-next-line no-console
            console.log("Subscribing ", stompClient);
            invokeSubscribe();
          } else {
            // eslint-disable-next-line no-undef
            clearInterval(intervalCounter);
          }
        }
      }, 500);
      // eslint-disable-next-line no-undef
      return () => clearInterval(intervalCounter);

    }, // eslint-disable-next-line
    [orderProgressNotification]
  );

  // eslint-disable-next-line
  const { status, stages } = orderProgressStage;

  const isOrderActive = status === "ACTIVE";
  const isOrderRemoved = status === "REMOVED" || status === "NO_ORDER";

  // if (!user?.isAuth) {
  //   navigate(`/${lang}/`);
  //   setLoginModal(true);
  //   return;
  // }

  // eslint-disable-next-line
  
  // eslint-disable-next-line no-console
  return (
    <section className="progress-page">
      <Container>
        {!status && <Loading absolute />}
        {isOrderRemoved && <OrderDataRemoved />}
        {isOrderActive && (
          <>
            <div className="progress-page__title">{thanksForOrder}</div>
            <p className="progress-page__description">{orderMessage}</p>
            <OrderStagesList stages={stages} />
            <ReviewsList />
          </>
        )}
      </Container>
    </section>
  );
};
