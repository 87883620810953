import { useEffect } from 'react';
import {useSelector} from "react-redux";

const usePaddle = () => {
  const { paddleConfig } = useSelector((state) => state.content);
  
  useEffect(() => {
    if (!paddleConfig || !paddleConfig.isEnabled) return;
    
    console.log('use paddle')
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.async = true;

    script.onload = () => {
      paddleConfig.sandbox && window.Paddle.Environment.set('sandbox');
      window.Paddle.Setup({ vendor: Number(paddleConfig.id) });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [paddleConfig?.id]);
};

export default usePaddle;
