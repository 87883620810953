import { useEffect, useState } from "react";
import { OrderStage } from "../OrderStage/OrderStage";
import { useTranslation } from "../../../../hooks";
import { CabinetInfo } from "../CabinetInfo/CabinetInfo";
import { classNames } from "../../../../utils/helpers/classNames";

export const OrderStagesList = ({ stages = {} }) => {
  const { PAID, PROXY_FOUND, ENABLED, NO_PROJECT, NO_PROXY, FORBIDDEN_TARGET, WAIT_FOR_CLIENT } = stages;

  // const [proxyFoundCount, setProxyFoundCount] = useState(0);
  // const [proxyEnabledCount, setProxyEnabledCount] = useState(0);
  const [proxyFoundCount, setProxyFoundCount] = useState(PROXY_FOUND?.done ? PROXY_FOUND?.proxyCount : 0);
  const [proxyEnabledCount, setProxyEnabledCount] = useState(ENABLED?.done ? ENABLED?.proxyCount : 0);

  const COUNTER_DURATION_MS = 1000;

  const {
    orderProgressPage: { orderCreated, orderPaid, proxyPicking, proxyEnabling, ips }
  } = useTranslation();

  const stageInfo = {
    "PAID": {
      title: !PAID?.done ? orderPaid?.waitingTitle : orderPaid?.successTitle,
      description: !PAID?.done ? orderPaid?.waitingDescription : orderPaid?.successDescription
    },
    "PROXY_FOUND": {
      title: !PROXY_FOUND?.done ? proxyPicking?.waitingTitle : proxyPicking?.successTitle,
      description: `${!PROXY_FOUND?.done ? "0" : proxyFoundCount}/${PROXY_FOUND?.totalCount} ${ips}`
    },
    "ENABLED": {
      title: !ENABLED?.done ? proxyEnabling?.waitingTitle : proxyEnabling?.successTitle,
      description: `${!ENABLED?.done ? "0" : proxyEnabledCount}/${PROXY_FOUND?.totalCount} ${ips}`
    }
  };

  const startIncreaseCount = (setter, maxCount) => {
    const delay = 100;
    const step = Math.ceil(maxCount / (COUNTER_DURATION_MS / delay));

    const interval = setInterval(() => {
      setter((prevCount) => {
        if (prevCount + step >= maxCount) {
          clearInterval(interval);
          return maxCount;
        }

        return prevCount + step;
      });
    }, delay);

    return interval;
  };

  // const getDelay = (proxyCount) => proxyCount / COUNTER_DURATION_MS;

  useEffect(() => {
    if (!PROXY_FOUND?.done) return;

    const interval = startIncreaseCount(setProxyFoundCount, PROXY_FOUND?.proxyCount);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PROXY_FOUND?.done]);

  useEffect(() => {
    if (!ENABLED?.done || proxyFoundCount !== PROXY_FOUND?.proxyCount) return;

    const interval = startIncreaseCount(setProxyEnabledCount, ENABLED?.proxyCount);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ENABLED?.done, proxyFoundCount]);

  return (
    <div className="progress-page__stage-container">
      <div className="progress-page__order-stage">
        <OrderStage
          stageNum="1"
          title={orderCreated?.successTitle}
          iconTag="bagIcon"
          success={true}
        />
      </div>
      <div className="progress-page__order-stage">
        <OrderStage
          stageNum="2"
          iconTag="walletIcon"
          title={stageInfo["PAID"].title}
          description={stageInfo["PAID"].description}
          inProcess={!PAID?.done}
          isLoop={!PAID?.done}
          success={PAID?.done}
        />
      </div>
      <div className="progress-page__order-stage">
        <OrderStage
          stageNum="3"
          iconTag="serverIcon"
          title={stageInfo["PROXY_FOUND"].title}
          description={stageInfo["PROXY_FOUND"].description}
          isProgress={PAID?.done}
          percentage={!PROXY_FOUND?.done ? 0 : 100}
          inProcess={PAID?.done && (!PROXY_FOUND?.done || proxyFoundCount !== PROXY_FOUND?.proxyCount)}
          success={PROXY_FOUND?.done && proxyFoundCount === PROXY_FOUND?.proxyCount}
          isError={NO_PROJECT?.done || NO_PROXY?.done || FORBIDDEN_TARGET?.done || WAIT_FOR_CLIENT?.done}
          noProject={NO_PROJECT?.done}
          noProxy={NO_PROXY?.done}
          forbiddenTarget={FORBIDDEN_TARGET?.done}
          waitForClient={WAIT_FOR_CLIENT?.done}
        />
      </div>
      <div className="progress-page__order-stage">
        <OrderStage
          stageNum="4"
          iconTag="onIcon"
          title={stageInfo["ENABLED"].title}
          description={stageInfo["ENABLED"].description}
          isProgress={PROXY_FOUND?.done}
          percentage={!ENABLED?.done || proxyFoundCount !== PROXY_FOUND?.proxyCount ? 0 : 100}
          inProcess={(PROXY_FOUND?.done && proxyFoundCount === PROXY_FOUND?.proxyCount) && (!ENABLED?.done || proxyEnabledCount !== ENABLED?.proxyCount)}
          success={ENABLED?.done && proxyEnabledCount === ENABLED?.proxyCount}
        />
      </div>
      <div className={classNames(
        "progress-page__cabinet-info",
        { "progress-page__cabinet-info--visible": ENABLED?.done && proxyEnabledCount === ENABLED?.proxyCount }
      )}>
        <CabinetInfo />
      </div>
    </div>
  );
};
