import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { DaysSelect } from "../../../../../../components/ui/DaysSelect/DaysSelect";
import { PriceItemTemplate } from "../PriceItemTemplate/PriceItemTemplate";
import { useDispatchedActions, useTranslation } from "../../../../../../hooks";
import { getRentPeriodsUpdated } from "../../../../../../utils/helpers/getRentPeriodsUpdated.helper";
import { calculatePrice } from "../../../../../../utils/helpers";
import { PROXY_TYPES } from "../../../../../../utils/constants";
import "./PriceItem.scss";

export const PriceItem = (
  { proxyCode, popular, initialRentPeriod, initialPrice, quantity, localCountry, controller, packageCountry }
) => {
  const [rentPeriodId, setRentPeriodId] = useState(initialRentPeriod);
  const [price, setPrice] = useState(initialPrice);
  const [isPriceLoading, setIsPriceLoading] = useState(false);

  const { activeData, rentPeriods } = useSelector((state) => state.content);

  const { lang } = useParams();

  const currentRentPeriods = getRentPeriodsUpdated(rentPeriods.data[proxyCode], proxyCode);
  const rentPeriod = currentRentPeriods[lang].find((rentPeriod) => rentPeriod.id === rentPeriodId);

  const isEuropeSelected = proxyCode === PROXY_TYPES.ipv4.code && localCountry?.code === "EUROPE";

  const { setConfigModal } = useDispatchedActions();
  const {
    formPlaceholders: { rentPeriod: rentPeriodLabel },
    prices: { priceItem: {
      proxyQuantity,
      price: priceLabel,
      pcs
    } }
  } = useTranslation();

  const rentPeriodHandler = (rentPeriod) => {
    setRentPeriodId(rentPeriod.id);

    const params = {
      countryId: isEuropeSelected ? packageCountry?.id : localCountry?.id,
      currencyId: activeData?.currency?.id,
      count: +quantity,
      rentPeriodId: rentPeriod.id,
      proxyType: proxyCode
    };

    calculatePrice(setPrice, params, lang, setIsPriceLoading, controller);
  };

  const handleBuyProxy = () => {
    if (isPriceLoading) {
      return;
    }

    setConfigModal({
      goalCode: "",
      quantity,
      days: rentPeriod,
      country: isEuropeSelected ? packageCountry : localCountry,
      proxyType: proxyCode,
      prices: price,
      activeEurope: isEuropeSelected,
      isQuantityNotCurrent: proxyCode === "IPv6" && +quantity < 10
    });
  };

  return (
    <PriceItemTemplate isPopular={popular} handler={handleBuyProxy} isLoading={isPriceLoading}>
      <div className="price-item">
        <div className="price-item__quantity-container">
          <span className="price-item__quantity-title">{proxyQuantity}</span>
          <div>
            <div className="price-item__quantity">{quantity} {pcs}</div>
            <div className="price-item__price-per-one">
              {`$${price.usdPrice} /${pcs}`}
              {/*{*/}
              {/*  (!price.usdPrice || isPriceLoading)*/}
              {/*    // ? <Skeleton width={50} height={14} rounded="2px" />*/}
              {/*    ? "—"*/}
              {/*    : `$${price.usdPrice} /${pcs}`*/}
              {/*}*/}
            </div>
          </div>
        </div>
        <div className="price-item__rent-period">
          <DaysSelect
            proxyCode={proxyCode}
            selectedRentPeriod={rentPeriod}
            onChange={rentPeriodHandler}
            label={rentPeriodLabel}
            isLoading={isPriceLoading}
          />
        </div>
        <div className="price-item__price-container">
          <span className="price-item__price-title">{priceLabel}</span>
          <span className="price-item__full-price">
            {/*{*/}
            {/*  (!price.usdAmount || isPriceLoading)*/}
            {/*    // ? <Skeleton width={50} height={31.2} rounded="2px" />*/}
            {/*    ? "—"*/}
            {/*    : `$${price.usdAmount}`*/}
            {/*}*/}
            {`$${price.usdAmount}`}
          </span>
        </div>
      </div>
    </PriceItemTemplate>
  );
};
