import { useCallback } from "react";

import { useLocation } from "react-router-dom";
// import { isSSR } from "../utils/helpers/isSSR";

export const useDetectDefaultLang = () => {
  const { pathname } = useLocation();

  const detectDefaultLang = useCallback(() => {
    // if (isSSR()) {
      const locale = pathname.slice(1, 3);
      return locale === "ru" ? "ru" : "en";
  //   } else {
  // if (pathname.includes("/ru")) {
  //   locale = "ru"
  // }
  // if (pathname.includes("/en")) {
  //   locale = "en"
  // }
  //     let userLang = navigator.language || navigator.userLanguage;
  //     if (userLang !== "ru") {
  //       userLang = "en";
  //     }
  //     return userLang;
  //   }
  }, []);

  return detectDefaultLang();
};
