import React from "react";

import { hydrateRoot, createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { App } from "./App";
import { setupStore } from "./redux/store";

import "./index.scss";
import { ApiService } from "services/ApiService";
import ErrorBoundary from "./components/common/ErrorBoundary";

const rootEl = document.getElementById("root");
// const root = ReactDOM.createRoot(rootEl)
// TODO
rootEl.style.opacity = 1;

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

// const optionRender = (store = {}) => root.render(
//     // <React.StrictMode>
//     <ErrorBoundary>
//       <Provider store={setupStore(store)}>
//         <Router>
//           <App />
//         </Router>
//       </Provider>
//     </ErrorBoundary>
//     // </React.StrictMode>
// );

const optionRender = (store = {}) => {
  const app = (
    <ErrorBoundary>
      <Provider store={setupStore(store)}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ErrorBoundary>
  )
  
  if (rootEl.hasChildNodes()) {
    return hydrateRoot(rootEl, app);
  } else {
    const root = createRoot(rootEl);
    
    return root.render(app);
  }
  
};

const getPrefix = () => {
  const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
  return prefersDarkMode.matches ? "-dark" : "-light";
};

const homeURL = process.env.REACT_APP_HOME_URL;

const initIcon = () => {
  const favicon = document.getElementById("favicon");
  const appleIcon = document.getElementById("appleIcon");
  const favicon32 = document.getElementById("favicon32");
  const favicon16 = document.getElementById("favicon16");
  const safariIcon = document.getElementById("safariIcon");
  
  if (favicon && appleIcon && favicon32 && favicon16 && safariIcon) {
    favicon.href = `${homeURL}/favicon${getPrefix()}.ico`;
    appleIcon.href = `${homeURL}/apple-touch-icon${getPrefix()}.png`;
    favicon32.href = `${homeURL}/favicon-32x32${getPrefix()}.png`;
    favicon16.href = `${homeURL}/favicon-16x16${getPrefix()}.png`;
    safariIcon.href = `${homeURL}/safari-pinned-tab${getPrefix()}.svg`;
  }
};

const initStore = async () => {
  try {
    const contentId = document.querySelector(
      `meta[name="ssr-content-id"]`
    ).content;
    if (contentId) {
      const req = await ApiService.cacheSSR(contentId);
      if (req.data.state) {
        return optionRender(req.data.state);
      }
    }
    return optionRender(window.__PRELOADED_STATE__);
  } catch (e) {
    return optionRender(window.__PRELOADED_STATE__);
  } finally {
    delete window.__PRELOADED_STATE__;
    document.getElementById("preloaded-state")?.remove()
  }
};

initStore();
initIcon();


