import MainPage from "../../pages/MainPage/MainPage";
import { OrderProgressPage } from "../../pages/OrderProgressPage/OrderProgressPage";

export const AppRoutes = [
  {
    path: "/:lang/",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: MainPage
  },
  {
    path: "/:lang/ipv6/",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: MainPage
  },
  {
    path: "/:lang/isp/",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: MainPage
  },
  {
    path: "/:lang/mobile/",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: MainPage
  },
  {
    path: "/:lang/:country/",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: MainPage
  },
  {
    path: "/:lang/api/recovery/:hash",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: MainPage
  },
  {
    path: "/:lang/payment/:status/",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: MainPage
  },
  {
    path: "/:lang/progress/",
    tag: "progress",
    metaData: "seo",
    title: "Order progress",
    description: "Order progress",
    component: OrderProgressPage
  }
];
