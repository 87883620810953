import { useEffect, useRef, useState } from "react";
// import { components } from "react-select";
import { useCurrentRentPeriod, useTranslation } from "../../../../../../../hooks";
// import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";
import { classNames } from "../../../../../../../utils/helpers/classNames";
import { ApiService } from "../../../../../../../services/ApiService";
import { errorToast } from "../../../../../../../utils/helpers/customToast.helper";
import { Radio } from "../../../../../../../components/ui/Radio/Radio";
import MobilePricesSelect from "./MobilePricesSelect/MobilePricesSelect";

export const ProxyItemBody = (props) => {
  const { data, setDays, desctop = false } = props;
  // Ref
  const ref = useRef(null);
  const isMounted = useRef(false);

  const { mobileProxy } = useTranslation();
  const rentPeriods = useCurrentRentPeriod("MOBILE");
  // const DropdownIndicator = (props) => (
  //   <components.DropdownIndicator {...props}>
  //     <IconSvg tag="triangleDown12" />
  //   </components.DropdownIndicator>
  // );
  const [prices, setPrices] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(data?.operatorWithMinPrice);
  const [selectedRotation, setSelectedRotation] = useState(data?.rotationWithMinPrice);

  const handlerSelect = (obj) => {
    if (obj) {
      setDays({ ...obj, label: obj?.value });
    }
  };

  const setSelectValue = (options) => {
    const [firstOption] = options;

    if (ref.current) {
      ref.current.select.setValue(firstOption);
    }
  };

  const getOperatorRotation = (currentOperator) => {
    const rotations = currentOperator.rotationMins;
    return [...rotations].sort((a, b) => a.min - b.min );
  };

  const getPriceOptions = (daysWithPrices) => rentPeriods.map((item) => {
      const currentPrices = daysWithPrices.find((p) => p.days === item.days);

      return {
        ...item,
        label: `${currentPrices.price} ${data?.currencyCode} ${mobileProxy.for} ${item.label} `
      };
    });

  const changeOperator = (operator) => {
    setSelectedOperator(operator);

    const rotations = getOperatorRotation(operator);
    const minRotation = rotations[0].min;

    setSelectedRotation(minRotation);
  };

  useEffect(() => {
    (async () => {
      if (!isMounted.current) {
        isMounted.current = true;

        return;
      }

      try {
        const params = {
          countryId: data?.countryId,
          mobileOperatorTag: selectedOperator.operatorTag,
          rotation: selectedRotation
        };

        const { status, data: pricesData } = await ApiService.getMobileDaysWithPrices(params);

        if (status !== 200) {
          throw status;
        }

        if (!pricesData.success) {
          return errorToast(pricesData.error);
        }

        const options = getPriceOptions(pricesData.prices);

        setSelectValue(options);
        setPrices(options);

        data.selectedOperator = selectedOperator;
        data.selectedRotation = selectedRotation;

      } catch (status) {
        errorToast(`Something went wrong, ${status}`);
      }

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperator, selectedRotation]);

  // set default days
  useEffect(() => {
    const options = getPriceOptions(data?.daysWithPrices);

    setSelectValue(options);
    setPrices(options);
    // if (rentPeriods.length > 0) {
    //   setDays(rentPeriods.find((item) => item?.days === data?.minDays));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentPeriods]);

  return (
    <>
    <div className="mobile-list__item-box">
      <div className="mobile-list__item-header">
        {data?.label || data?.code}
      </div>

        {desctop ? (
          <div className="mobile-list__item-desctop">
            <div className="mobile-list__item-info">
              <span className="mobile-list__item-info-text">
                {mobileProxy?.chanel}
              </span>
              {data?.label.props.children[1]}
            </div>{" "}
            <div className="mobile-list__item-info">
              <span className="mobile-list__item-info-text">
                {mobileProxy?.traffic}
              </span>
              {mobileProxy?.limit}
            </div>
          </div>
        ) : (
          <div className="mobile-list__item-info">
            <span className="mobile-list__item-info-text">
              {mobileProxy?.chanel}
            </span>
            {data?.label.props.children[1]}
          </div>
        )}

        <div className="mobile-list__item-info">
          <span className="mobile-list__item-info-text">
            {mobileProxy?.operator}
          </span>
          <ul className="mobile-list__operators">
            {data?.operators &&
              data?.operators.map((item) => (
                <li className={classNames(
                  "mobile-list__operator"
                  // { "mobile-list__operator--active": item?.operatorTag === selectedOperator.operatorTag }
                )}
                    key={item?.operatorTag}
                    onClick={() => changeOperator(item)}
                >
                  <Radio isActive={item?.operatorTag === selectedOperator.operatorTag} />
                  {item?.operatorName}
                </li>
              ))}
          </ul>
        </div>
        <div className="mobile-list__item-info">
          <span className="mobile-list__item-info-text">
            {mobileProxy?.changeIP}
          </span>
          <ul className="mobile-list__rotation-list">
            {getOperatorRotation(selectedOperator).map(({ min: i }) => (
              <li className={classNames(
                "mobile-list__rotation-item",
                { "mobile-list__rotation-item--active": i === selectedRotation }
                )}
                  key={i}
                  onClick={() => setSelectedRotation(i)}
              >
                <Radio isActive={i === selectedRotation} />
                {i === 0 ? mobileProxy?.changeIPauto : `${i} ${mobileProxy?.changeIPTime}`}
              </li>
            ))}
          </ul>
        </div>
        {!desctop && (
          <div className="mobile-list__item-info">
            <span className="mobile-list__item-info-text">
              {mobileProxy?.traffic}
            </span>
            {mobileProxy?.limit}
          </div>
        )}
      </div>
      <div className="mobile-list__item-price">
        {mobileProxy?.price}
        {/*<Select*/}
        {/*  // isLoading={true}*/}
        {/*  ref={ref}*/}
        {/*  className="mobile-list__item__select"*/}
        {/*  classNamePrefix="mobile-list__item__select"*/}
        {/*  isSearchable={false}*/}
        {/*  options={prices}*/}
        {/*  placeholder={""}*/}
        {/*  menuPlacement="auto"*/}
        {/*  onChange={handlerSelect}*/}
        {/*  components={{ DropdownIndicator }}*/}
        {/*  menuPortalTarget={document.querySelector("body")}*/}
        {/*/>*/}
        <MobilePricesSelect options={prices} handlerSelect={handlerSelect} />
      </div>
    </>
  );
};
