export const getLanguages = (languages) => {
  const langMass = [];

  for (const key in languages) {
    if (languages.hasOwnProperty(key)) {
      langMass.push(languages[key]);
    }
  }

  return langMass;
};
