import { useLayoutEffect } from "react";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import "./OrderDataRemoved.scss";
import { useTranslation } from "../../../../hooks";

export const OrderDataRemoved = () => {
  const { orderProgressPage: { noOrderMessage } } = useTranslation();

  useLayoutEffect(() => {
    const tags = document.getElementsByTagName("main");
    const orderRemoved = document.querySelector(".order-removed");
    const container = orderRemoved.closest(".container");
    const [main] = tags;

    main.style.display = "flex";
    main.style.justifyContent = "center";

    container.style.height = "100%";

    return () => {
      main.style.display = "block";
      container.style.height = "auto";
    };
  }, []);

  return (
    <div className="order-removed">
      <div className="order-removed__icon">
        <IconSvg tag="emptyBox" />
      </div>
      <div className="order-removed__text">
        {noOrderMessage}
      </div>
    </div>
  );
};
