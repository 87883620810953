import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { mergeDeep } from "../utils/helpers";

export const useTranslation = () => {
  // **Props
  const { lang } = useParams();

  // **Redux state
  const { translation } = useSelector((state) => state.translation);
  const langDefault = "en";

  const currentTranslation = lang && translation?.hasOwnProperty(lang) ? translation[lang] : translation[langDefault];

  const defaultTranslation = translation[langDefault];

  return mergeDeep({}, defaultTranslation, currentTranslation);
};
