import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";
// import { useParams } from "react-router-dom";

import InputMask from "react-input-mask";
import { classNames as classNamesHelperFunc } from "../../../utils/helpers/classNames";
// import { useDispatchedActions } from "../../../hooks/useDispatchedActions";
import { inputCorrection, isSSR } from "../../../utils/helpers";
import { useTranslation } from "../../../hooks";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import { emailPattern } from "../../../utils/constants/emailPattern";
import "./ModalInput.scss";
import { Loading } from "../Loading/Loading";

export const ModalInput = (props) => {
  // **Props
  const {
    rows,
    cols,
    type,
    name,
    defaultValue,
    placeholderNoData,
    placeholder = " ",
    // placeholder,
    handler,
    rules,
    mask,
    label,
    removable,
    quantity,
    disabled,
    readonly,
    removeIcon,
    ipMask,
    loader,
    rightIcon,
    // eslint-disable-next-line no-empty-function
    watch = () => {}
  } = props;
  // const { lang } = useParams();

  // Dispatch
  // const { setConfModal } = useDispatchedActions();

  // **Local State
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [quantityValue, setQuantityValue] = useState(defaultValue);
  // const debouncedQuantity = useDebounceValue(quantityValue, 500);

  // **Hooks
  const { notifications } = useTranslation();

  // **Ref
  const inputRef = useRef(null);
  const registerOptions = useRef(null);

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();

  const [p, setP] = useState(defaultValue);
  // Password handler
  const showPasswordHandler = () => {
    setIsPasswordShown(!isPasswordShown);
  };

//   useEffect(() => {
//     if (!quantity) {
//       return;
//     }
//
//     const validQuantity = getValidQuantity(proxyType, quantityValue);
//
//     if (validQuantity !== defaultValue) {
//       setQuantityValue(validQuantity);
//       handler(validQuantity);
//       setValue("quantity", validQuantity);
//     }
// // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [debouncedQuantity]);

  // Quantity handler
  const quantityHandler = (e) => {
    inputCorrection(e, handler);
    inputCorrection(e, setQuantityValue);

    if (e.target.value === "0" && e.target.value.length === 1) {
      setValue("quantity", "");
    }
  };

//   useEffect(() => {
//     const validQuantity = getValidQuantity(proxyType, quantityValue);
//
//     if (validQuantity === defaultValue) {
//       return;
//     }
//
//     if (!proxyType) {
//       return;
//     }
//     const initQuantity = getInitialQuantity(proxyType);
//
//     setQuantityValue(initQuantity);
//     handler(initQuantity);
//     setValue("quantity", initQuantity);
// // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [proxyType]);

  // Define correct classnames
  const classNames = () => {
    let className = "modal__input";

    if (errors[name]) {
      className += " bordered";
    }

    if (type === "password" || removable || mask || quantity) {
      className += " modal__input--icon";
    }

    if (type === "checkbox") {
      className += " modal__input-checkbox";
    }

    if (type === "email") {
      className += " modal__input-email";
    }

    return className;
  };

  // Set ip mask for input
  useEffect(() => {
    (async () => {
      if (inputRef.current) {
        const inputMaskLazy = await import("inputmask");

        const inputMask = new inputMaskLazy.default({
          mask: "9[9][9].9[9][9].9[9][9].9[9][9]"
        });

        inputMask.mask(inputRef.current);
      }
    })();
  }, [inputRef]);

  // Set register options to registerOptions ref
  useEffect(() => {
    // Checking if registre options is empty
    if (!registerOptions.current) {
      // Destructuring register method
      const { ref, ...rest } = register(name, rules);

      // Write options from register method to ref
      registerOptions.current = { ref, rest: { ...rest } };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mask]);

  const [space, setSpace] = useState(0);

  const input = useRef();
  const inputValue = useRef();

  useLayoutEffect(() => {
    if (!isSSR()) {
      if (inputValue.current && input.current) {
        const gap = 4;
        const padding = parseInt(window.getComputedStyle(input.current.firstChild).paddingLeft);
        const { width } = inputValue.current.getBoundingClientRect();
        setSpace(width + padding + gap);
      }
    }
  }, [quantityValue]);

  if (ipMask) {
    // eslint-disable-next-line func-style
    function checkIpValue(value) {
      const subIps = value.split(".");
      if (subIps.length > 4) {
        return false;
      }

      const invalidSubIps = subIps.filter((ip) => {
        const item = parseInt(ip);
        return item < 0 || item > 255;
      });

      if (invalidSubIps.length !== 0) {
        return false;
      }
      let emptyIpCount = 0;
      subIps.forEach((ip) => {
        if (ip === "") {
          emptyIpCount++;
        }
      });
      if (emptyIpCount > 1) {
        return false;
      }
      return true;
    }

    return (
      <div className={classNames()}>
        <InputMask
          formatChars={{
            "9": "[0-9.]"
          }}
          className="input"
          name={name}
          readOnly={readonly}
          defaultValue={defaultValue}
          placeholder="_._._._"
          mask="999999999999999"
          maskChar={null}
          alwaysShowMask={false}
          beforeMaskedValueChange={(newState, oldState) => {
            const oldValue = oldState.value;
            let { value } = newState;
            let { selection } = newState;
            let cursorPosition = selection ? selection.start : null;
            const result = checkIpValue(value);

            if (!result) {
              value = value.trim();
              // try to add . before the last char to see if it is valid ip address
              const newValue = `${value.substring(0, value.length - 1) }.${ value.substring(value.length - 1)}`;

              if (checkIpValue(newValue)) {
                cursorPosition++;
                selection = { start: cursorPosition, end: cursorPosition };
                value = newValue;
              } else {
                value = oldValue;
              }
            }

            return {
              value,
              selection
            };
          }}
          {...register(name, rules)}

        />
        <label className="modal__input-label modal__input-label--ip-mask modal__input-label--top">
          {label}
        </label>
        {errors[name] && (
          <p className="modal__select-error-text">
            {notifications?.wrongIPInput}
          </p>
        )}
        {rightIcon && (
          <div className="modal__input-icon">
            {rightIcon}
          </div>
        )}
        { handler && (
          <div className="modal__input-icon" onClick={handler}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
          </div>
        )}
      </div>
    );
  }

  if (mask) {
    return (
      <div className={classNames()}>
        <label>
          <Controller
            name={name}
            control={control}
            rules={{
              validate: () =>
                /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                  getValues(name)
                )
            }}
            render={({ field }) => (
              <>
                <input
                  {...field}
                  {...registerOptions.current?.rest}
                  ref={(e) => {
                    registerOptions.current?.ref(e);
                    inputRef.current = e;
                  }}
                  type="text"
                  inputMode="numeric"
                  defaultValue={defaultValue}
                  readOnly={readonly}
                />
                {errors[name] && (
                  <p className="modal__select-error-text">
                    {notifications?.wrongIPInput}
                  </p>
                )}
              </>
            )}
          />
          {/* <input
            {...registerOptions.current?.rest}
            ref={(e) => {
              registerOptions.current?.ref(e);
              inputRef.current = e;
            }}
            type="text"
            inputMode="numeric"
          /> */}
          { !removeIcon && (
            <div className="modal__input-icon" onClick={handler}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="15" y1="9" x2="9" y2="15"></line>
                <line x1="9" y1="9" x2="15" y2="15"></line>
              </svg>
            </div>
          )}
        </label>
      </div>
    );
  }

  if (label) {
    return (
      <div className={classNames()}>
        {/*{console.log(watch(name))}*/}
        <input
          className={classNamesHelperFunc(
            "input",
            { "modal__input-login-data": type === "password" || type === "email" }
          )}
          id={name}
          defaultValue={defaultValue}
          onChange={handler}
          type={isPasswordShown ? "text" : type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readonly}
          {...register(name, rules) }
        />
        <label htmlFor={name} className={classNamesHelperFunc(
          "modal__input-label",
          { "modal__input-label--top": Boolean(watch(name)) }
        )}>
          {label}
        </label>
        {type === "password" && (
          <div className="modal__input-icon" onClick={showPasswordHandler}>
            {isPasswordShown ? (
              <IconSvg tag="hiddenPas" />
            ) : (
              <IconSvg tag="showPas" />
            )}
          </div>
        )}
        {rightIcon && (
          <div className="modal__input-icon">
            {rightIcon}
          </div>
        )}
        {/* <label>
          <span className="modal__input-label">{label}</span>
          <input
            defaultValue={defaultValue}
            onChange={handler}
            type={isPasswordShown ? "text" : type}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            {...register(name, rules)}
          />
          {type === "password" && (
            <div className="modal__input-icon" onClick={showPasswordHandler}>
              {isPasswordShown ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                  <line x1="1" y1="1" x2="23" y2="23"></line>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              )}
            </div>
          )}
        </label> */}
      </div>
    );
  }

  if (removable) {
    return (
      <div className={classNames()}>
        <label>
          <span className="modal__input-label">{label}</span>

          <input
            defaultValue={defaultValue}
            onChange={handler}
            type={type}
            disabled={disabled}
            readOnly={readonly}
            placeholder={placeholder}
            {...register(name, rules)}
          />
          <div className="modal__input-icon" onClick={handler}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
          </div>
        </label>
      </div>
    );
  }

  if (quantity) {
    return (
      <div className={classNames()} ref={input}>
        <input
          type={type}
          placeholder={placeholderNoData}
          defaultValue={defaultValue}
          onInput={quantityHandler}
          autoComplete="off"
          disabled={disabled}
          readOnly={readonly}
          {...register(name, rules)}
        />
        {quantityValue && (
          <span
            style={{ left: `${space}px` }}
            className="modal__input-pieces"
          >
            {placeholder}
          </span>
        )}
        <span
          ref={inputValue}
          style={{ position: "absolute", opacity: "0" }}
        >
        {quantityValue}
      </span>
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <div className={classNames()}>
        <input
          type={type}
          id={name}
          onInput={handler}
          defaultChecked={defaultValue}
          disabled={disabled}
          checked={p}
          {...register(name, rules)}
        />
        <label form={name} onClick={() => setP((state) => !state)}>
          <div className={"checked"}>
            <IconSvg tag="checked" />
          </div>{" "}
          text
        </label>
        {/* <label>

          <input
            type={type}
            onInput={handler}
            defaultChecked={defaultValue}
            disabled={disabled}
            readOnly={readonly}
            {...register(name, rules)}
          />
          {lang === "ru" ? (
            <span>
              Я согласен{" "}
              <button type="button" onClick={() => setConfModal(true)}>
                с условиями
              </button>
            </span>
          ) : (
            <span>
              I agree{" "}
              <button type="button" onClick={() => setConfModal(true)}>
                with the conditions
              </button>
            </span>
          )}
        </label> */}
      </div>
    );
  }

  if (type === "email") {
    return (
      <div className={classNames()}>
        <Controller
          name={name}
          control={control}
          rules={{
            pattern: emailPattern
          }}
          defaultValue={defaultValue || ""}
          render={({ field }) => (
            <>
              <input
                {...field}
                type={type}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onInput={handler}
                disabled={disabled}
                readOnly={readonly}
                // autoComplete="off"
                {...register(name, rules)}
              />
              {loader && <Loading inputLoader absolute />}

              {errors[name] && (
                <p className="modal__select-error-text">
                  {notifications?.wrongEmailInput}
                </p>
              )}
            </>
          )}
        />
      </div>
    );
  }

  if (type === "textarea") {
    return (
      <div className={`${classNames()} modal__input__textarea-container`}>
        {label && (
          <label className="modal__input__textarea-label">
            {label}
          </label>
        )}
        <textarea
          className="modal__input__textarea"
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          // value={value}
          // onChange={(e) => onChange(e.target.value)}
          {...register(name, rules) }
        />
      </div>
    );
  }

  return (
    <div className={classNames()}>
      <input
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onInput={handler}
        disabled={disabled}
        readOnly={readonly}
        {...register(name, rules)}
      />
    </div>
  );
};
