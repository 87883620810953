import { createSlice } from "@reduxjs/toolkit";

const translationEn = {
  header: {
    logoAria: "Go to the main page",
    logoAlt: "Proxy Ipv4 - Company Logotype",
    ariaBurger: "Open or close navigation menu",
    solutions: {
      solutionsNav: "Solutions",
      tgBot: "Telegram bot",
      addonsTitle: "Extensions:"
    },
    menu: {
      proxyNav: {
        name: "Buy Proxy",
        indicator: "dropdown open indicator",
        subName: "Proxy",
        subNameMobile: "Mobile proxy"
      },
      price: "Prices",
      feedback: "Reviews",
      faq: "FAQ",
      contacts: "Contacts",
      back: "Go back"
    },
    cabinet: {
      login: "Log in",
      loginIsAuth: "Dashboard",
      logout: "Exit"
    },
    formElem: {
      email: {
        label: "Your email",
        placeholder: "Type your e-mail"
      },
      password: {
        label: "Your password",
        placeholder: "Type password"
      },
      repeatPassword: {
        label: "Repeat password",
        placeholder: "Type password"
      },
      newPassword: {
        label: "Your new password",
        placeholder: "Type password"
      },
      repeatNewPassword: {
        label: "Repeat new password",
        placeholder: "Type password"
      },
      wrongPassword: "Password mismatch",
      invalidEmail: "Invalid email address",
      invalidPassword: "Invalid password",
    },
    topList: "Most popular",
    subList: "All countries",
    modalCabinet: {
      title: "Authorization",
      remember: "Remember me",
      enterBtn: "Enter",
      recoveryBtn: "Forgot password?",
      textSignUp: "Don't have an account?",
      signUpBtn: "Sign Up"
    },
    modalRecovery: {
      title: "Recovering your password",
      recoveryBtn: "Restore"
    },
    modalSingUp: {
      title: "Registration",
      singUpBtn: "Create an account",
      textLogin: "Do you have an account?",
      loginBtn: "Sign In",
      successMessage: "Thank you for registering on our website!\n" +
        "We have sent your login information to your email."
    },
    notifications: {
      title: "Notifications",
      clearAll: "Clear all",
      expand: "Expand",
      collapse: "Collapse",
      noData: "There are no new notifications.",
      showMore: "Show more"
    }
  },
  footer: {
    logoAria: "Go to the main page",
    logoAlt: "Proxy Ipv4 - Company Logotype",
    privacyPolicy: {
      title: "Privacy Policy",
      lang: "en"
    },
    publicOffer: {
      title: "Public offer",
      lang: "en"
    },
    termsOfUse: {
      title: "Terms of Use",
      lang: "en"
    },
    returnPolicy: {
      title: "Return policy",
      lang: "en"
    },
    cookiePolicy: {
      title: "Cookie Policy",
      lang: "en"
    }
  },
  main: {
    buyBtn: "Buy proxy",
    selectProxy: {
      IPv4: "Proxy IPv4",
      IPv6: "Proxy IPv6",
      MOBILE: "Mobile proxy",
      ISP: "ISP Proxy"
      
    },
    seo: {
      showAllButton: "Show all"
    },
    sortOptions: {
      price: "price",
      popular: "popular"
    },
    days: "Days",
    agreeWithRules: {
      iAgree: "I agree to",
      and: "and",
      privacyPolicy: "privacy policy",
      termsOfUse: "terms of use",
      warningMessage: "You have to accept conditions our website to continue."
    },
  },
  orderForm: {
    title: "Order form",
    fullPrice: "To pay",
    payBtn: "Pay",
    rotation: "Rotation :",
    operator: "Operator :",
    description: "The order will be processed automatically right after the payment confirmation."
  },
  formPlaceholders: {
    country: "Select country",
    protocol: "Select protocol",
    rentPeriod: "Lease term",
    operators: "Carriers",
    quantity1: "Quantity",
    quantity2: "pcs",
    rotation: "Select rotation IP",
    proxyForField: "Proxy for",
    proxyGoalField: {
      select: "Purpose of use",
      input: "Enter target"
    },
    authField: "Authorization method",
    email: "Your e-mail",
    paymentField: "Payment method",
    paymentAmount: "Enter amount",
    currency: "Currency",
    paymentDisabled: "Enter e-mail first",
    search: "Search",
    enterCountryName: "Enter country name"
  },
  prices: {
    title: "Proxy prices",
    sort: "Sort by:",
    modalTitle: "Choose the country",
    more: "More",
    seo: {
      title: "For SEO",
      subGoal: "Google, Scrapebox, Trafficbot, etc.",
      subGoalIPv6: "Google, Scrapebox, Trafficbot, Yahoo, etc.",
      advantages: [
        "Unlimited traffic",
        "HTTP/S, SOCKS5",
        "Individually",
        "Technical support 24/7"
      ]
    },
    smm: {
      title: "For SMM",
      subGoal: "Instagram, Facebook, Twitter, TikTok, etc.",
      subGoalIPv6: "Instagram, Telegram, LinkedIn, Facebook, YouTube, etc.",
      advantages: [
        "Unlimited traffic",
        "HTTP/S, SOCKS5",
        "Individually",
        "Technical support 24/7"
      ]
    },
    other: {
      title: "Other tasks",
      subGoal: "Gambling websites, Craigslist, Amazon, eBay, Netflix, etc.",
      subGoalIPv6: "Google services, Netflix, Alibaba, Walmart, etc.",
      advantages: [
        "Unlimited traffic",
        "HTTP/S, SOCKS5",
        "Individually",
        "Technical support 24/7"
      ]
    },
    buyBtn: "Buy proxy",
    query: "Choose the country, please",
    minQuantityIPv6: "Minimum order for IPv6 10 pcs.",
    notCurrentPaymantBalanceData: "Minimum deposit amount 1$.",
    lowAmount:
      "Attention! Orders for amounts up to $5 are paid from the personal account balance.",
    lowAmountBtn: {
      cabinet: "Personal account",
      replenish: "Refill the balance"
    },
    notAuthBalance: "You must be logged in to use the balance system.",
    infoModalSelectBalance: "Change the payment method to \"Balance\"",
    lowBalance: {
      main: "Insufficient balance. To continue, replenish the balance or change the payment system",
      title: "Insufficient balance",
      balance: "Balance",
      textContinue:
        "Click «continue» to change the payment system, and repeat your order.",
      textReplenish:
        "To replenish the balance, click «replenish» and complete the operation.",
      btnContinue: "Continue",
      btnReplenish: "Replenish"
    },
    priceItem: {
      proxyQuantity: "Quantity of proxies",
      price: "Price",
      pcs: "pcs",
      popular: "Popular",
      yourQuantity: "Your quantity",
      enterpriseDescription: "Please contact our sales department to receive individual terms for using the service",
      enterpriseModal: {
        name: "Name",
        selectSocials: "Type of communication",
        contacts: "Contacts",
        describeResources: "Describe the necessary resources for your goals",
        companyActivity: "Describe the activities of your company",
        other: "Other",
        send: "Send",
        successfulSendingMessage: "Thanks for reaching out.\n" +
          "We will contact you as soon as possible.",
        buttonText: "Contact us"
      }
    },
  },
  mobileProxy: {
    chanel: "Chanel",
    days: "days",
    operator: "Operator",
    changeIP: "Change IP",
    traffic: "Traffic",
    price: "Price",
    limit: "Unlimited",
    changeIPTime: "min",
    changeIPauto: "via link",
    for: "for"
  },
  advantages: { title: "Benefits of IPv4" },
  proxyManager: {
    title: "Simplified proxy management",
    ipv6Message: "Simplified IPv6 proxies management",
    ispMessage: "Simplified ISP proxies management",
    mobileMessage: "Simplified mobile proxies management",
    countryTypeMessage: "Simplified management of your proxies of _",
    list: [
      "Automation via client API",
      "Balance and financial records",
      "Export in various convenient formats",
      "Commenting and categorizing proxies using folders"
    ]
  },
  reviews: {
    title: "Reviews about us",
    titleMain: "Reviews about Proxy-IPv4",
    togglerAriaLabel: "Open or close question",
    allReviews: "All reviews"
  },
  faq: {
    title: "We answer your questions",
    description:
      "Check out the list of frequently asked questions, you may find yours.",
    ask: "Ask your question"
  },
  cabinet: {
    profile: {
      title: "Profile",
      formTitle: "Change current password",
      formBtn: "Change password",
      wrongPassword: "Password mismatch",
      wrongPasswordLength: "Password must be at least 6 characters",
      wrongPasswordChars: "Password must contain only latin characters and digits",
      wrongEmail: "Wrong login or password",
      wrongNewEmail: "",
      theSamePass: "Old and new passwords are the same",
      edit: "Edit",
      save: "Save",
      close: "Close",
      email: {
        title: "Your email:",
        info: "Your current email address:",
        new: "Your new email",
        enterEmail: "Enter your new email",
        password: "Your current password",
        enterPassword: "Enter current password"
      },

      password: {
        title: "Your password:",
        info: "Last time you updated your password:",
        year: "year aro",
        years: "years aro",
        yearsTwo: "years aro",
        month: "month ago",
        months: "months ago",
        monthsTwo: "months ago",
        day: " day ago",
        days: "days ago",
        daysTwo: "days ago",
        today: "today"
      }
    },
    newOrder: {
      tabName: "New order"
    },
    distribution: {
      title: "Distribution",
      descriptions: "Notification settings",
      formTitle: "Distribution",
      formBtn: "Save",
      system: "System notifications",
      other: "Others"
    },
    telegram: {
      title: "Telegram",
      descriptions: "Notifications are available",
      bot: "Telegram bot",
      notifications: "Notifications",
      turnOn: "Turn on",
      turnOff: "Turn off",
      botLink: "after authorization in the bot",
      formBtn: "Save"
    },
    format: {
      title: "Export format",
      example: "Example:",
      variables: "Available variables:",
      textClicking:
        "(by clicking on a variable, it is inserted into the input field)"
    },
    documentation: {
      title: "API",
      main: {
        whiteIp: "White IP",
        placeholderKey: "press Generate key",
        placeholderIP: "enter IP",
        button: {
          generateKey: "Generate key",
          save: "Save",
          link: "API Documentation"
        },
        descriptions: {
          key: "Do not share this key with third parties",
          infoOne: "You can only allow API access from a specific IP address.",
          infoTwo: "You can add up to 3 IP addresses separated by commas."
        }
      },
      descriptionsAPI: {
        title: "Developers API",
        mainTitle: "General requests",
        ipv4Title: "IPv4 requests",
        ispTitle: "ISP requests",
        ipv6Title: "IPv6 requests",
        mobileTitle: "Mobile requests",
        info: "Api will allow you to integrate the purchase and renewal of a proxy into your service or application. The interaction of the partner with the system, as well as the system with the partner, occurs through “application / json” requests and responses. All communication takes place in UTF-8 encoding. The system implements protection against DOS attacks; no more than 60 requests per second is allowed. If more than 60 requests per second are sent, the response will be #error_id=2#. #Do you need a higher limit?#",
        responseBodyExample: "Example body answer",
        responseParams: "Response params",
        reqwestParams: "Request parameters",
        reqwestExample: "Example request",
        reqwestExampleBody: "Example body request",
        required: "Necessary",
        errorsTitle: "Possible mistakes",
        errorExample: "Error Example",
        errorIds: "ID errors"
      }
    },
    notifications: {
      tabName: "Notifications"
    },
    orders: {
      title: "Order table",
      formTitle: "Extend proxy",
      formBtn: "Extend",
      rowPerPage: "Proxies for page",
      separatorText: "of",
      column: {
        copy: "Copy ip",
        link: "Link",
        rotation: "Rotation",
        copyLink: "Copy link",
        ip: {
          name: "IP",
          imgAlt: "clue",
          login: "Login",
          password: "Password",
          ip: "IP auth",
          port: "Port",
          http: "HTTP/S",
          socks: "SOCKS5",
          protocol: "Protocol",
          ipStatus: "IP Status",
          active: "Active",
          ending: "Ending"
        },
        autoRenewal: {
          title: "Auto renewal",
          enabled: "Enabled",
          disabled: "Disabled"
        },
        notes: {
          title: "Notes",
          placeholder: "Enter your note"
        },
        country: "Country IP",
        term: "Active until",
        date: "Date",
        amount: "Amount",
        balanceOperation: {
          title: "Type of Operation",
          plus: "Replenishment the balance",
          minus: "Service payment",
          plusAdmin: "Replenishment the balance by admin",
          minusAdmin: "Write-off the balance by Admin"
        },
        state: {
          title: "IP status",
          active: "Active",
          inactive: "Inactive"
        }
      },
      applyBtn: "Apply",
      export: "Export",
      save: "Save",
      download: "Download",
      cancel: "Cancel",
      slectFileType: "Type of file",
      noDataComponent: "There's nothing here",
      renewalErrorMessages: {
        noCountry: "Sorry, the feature is not available for the following countries: _",
        noIp: "Sorry, renewal of this IP is not available at the moment",
        noMobileOperator: "Sorry, mobile operator _ is not available at the moment",
        unexpectedError: "Something went wrong"
      },
      autoExtending: {
        title: "Insert as a list",
        description: "Write a list of IPs you want to renew",
        placeholder: "Enter IP addresses...",
        noMatches: "No matches found",
        allFound: "Found -found- IPs",
        partFound: "Found -found- out of -entered- entered IPs",
        modals: {
          confirm: {
            autoRenewal: "Auto renewal",
            selectedProxy: "Proxy is selected:",
            pcs: "pcs",
            autoRenewalConfirm: "Do you confirm the auto-renewal?",
            confirmationHintPart1: "The chosen proxy will be extended for",
            confirmationHintPart2: "the funds will be withdrawn from your account",
            hint: "Proxies will be renewed for the same period as the order itself.",
            hintExample: "Example: bought for 7 days, renewal will also be every 7 days.",
            autoRenewalButton: "Auto-renewal",
            confirmButton: "Confirm",
            cancelButton: "Cancel"
          },
          cancel: {
            confirmCanceling: "Do you confirm the cancellation of auto-renewal?",
            hint: "Proxies will be active until the end of the validity period and will not be renewed for the next period.",
            confirmButton: "Confirm",
            cancelButton: "Cancel"
          },
          noCash: {
            description: "Your balance does not have enough funds to use the auto-renewal feature.",
            hint: "Please top up your balance.",
            cancel: "Cancel",
            topUp: "Top up"
          }
        }
      },
      folders: {
        folderSelectLabel: "Folder name",
        moveToFolderSelectLabel: "Move to folder",
        generalFolder: "All",
        createFolder: "Create new folder",
        modals: {
          createFolder: "Create folder",
          editName: "Edit name",
          inputLabel: "Folder name",
          deleteNotification: "Are you sure you want to delete the folder",
          save: "Save",
          cancel: "Cancel",
          remove: "Delete",
          folderAlreadyExist: "Folder with this name already exist."
        }
      },
      filter: {
        all: "All",
        resetButton: "Reset",
        saveButton: "Save",
        filterByNote: "Filter by note",
        enterNote: "Enter note...",
        dateFilter: "Filter by date",
        dateErrorMessage: "Date format is not valid",
        inValidDateRange: "Invalid date range",
        from: "From",
        to: "To",
        country: "Country",
        ipStatus: "IP Status"
      },
      access: {
        title: "Access",
        descriptionLoginPass: "Your login and password for proxy authorization.",
        descriptionIp: "Add the IP address using the form below and save. Changes will take effect automatically within 1 minute.",
        loginTabTitle: "Login/Password",
        ipTabTitle: "By IP",
        loginField: "Login",
        passwordField: "Password",
        clearData: "Clear data",
        save: "Save",
        cancel: "Cancel",
        remove: "Remove",
        successMessage: "Success",
        loginExist: "Login already exist",
        deleteNotification: "Are you sure you want to delete the IP address",
        addIp: "Add IP",
        accessDataSaved: "Your authentication data has been successfully saved."
      },
      notes: {
        note: "Note",
        comment: "Comment",
        enterNote: "Enter your note"
      },
      ipReplacement: {
        title: "IP Replacement",
        description: "Please select a reason for the replacement from the provided list or specify your own option using the appropriate choice.",
        commentPlaceholder: "Comment",
        successfullySent: "The request for ip replacement has been successfully sent",
        ipReplacementReason: "Please specify the reason for changing the IP address.",
        somethingWentWrongMsg: "Something went wrong please try later",
        tooOftenMsg: "IP can be changed once a day. You have already sent the following ips to change",
        alreadyRequestedMsg: "The request to replace these ips is already under review:",
        reasonTitle: "Why do you want to replace the IP:",
        reasons: {
          notWork: "It doesn't work",
          incorrectLocation: "Incorrect location",
          changeNetwork: "Change the network",
          lowSpeed: "Low speed",
          custom: "Your option"
        }
      },
    },
    balance: {
      description: "Minimum deposit amount 5$",
      title: "Balance",
      label: "Your balance:",
      button: "Top up",
      lines: "lines"
    },
    faq: {
      tabName: "FAQ"
    }
  },
  paymentModal: {
    success:
      "Thank you for your payment, your order is already being processed!",
    cancel: "Payment failed, your payment has not been received",
    pending: "Please, wait!",
    replenishment: {
      success: "Thank you, your balance has been replenished by the amount",
      cancel: "Payment failed, your payment has not been received",
      pending: "Please, wait!"
    }
  },
  notifications: {
    wrongIPInput: "enter correct IP",
    wrongEmailInput: "enter correct e-mail",
    wrongEmail: "enter correct e-mail",
    error: "Something went wrong!",
    errorCaptcha: "Something went wrong, please try again later!",
    errorMinLength: "goal must be at least 5 characters",
    errorUserPaymentSystem: "Somesing wrong with payment system please choose another or try again later!",
    copy: "Copied",
    loginError: "Wrong email or password!",
    registerError: "Register error!",
    registerErrorWrongEmail: "Please enter existing mail",
    email: "Check your email! A notification has been sent to you!",
    passwordChange: "Password was changed successfully!",
    emailChange: "Email was changed successfully!",
    distributionChange: "Distribution was changed successfully!",
    minPrice: "Minimal price for your order is",
    captcha: "You are a robot!",
    wrongIp: "You entered an invalid IP address!",
    incorrectFormat: "Incorrect format!",
    serverError: "Temporary problem on the server, please try again later.",
    logout: "You are about to log out of your account.",
    documentation: {
      key: "You need to generate a key!",
      amount: "The maximum number of Ip is 3pcs!",
      ip: "Incorrect Ip entered!",
      success: "Data saved successfully!",
      changeKey: "Attention the former Token will be rewritten!",
      continue: "Continue",
      cancel: "Cancel",
      minPriceForPaymentSystem: "The minimum deposit amount using this payment system is "
    },
    tempMail: "Sorry, temporary emails cannot be used.",
    invalidIp: "This IP cannot be used as an authorized IP"
  },
  subgoals: {
    keyCollector: "KeyCollector",
    parsingYandex: "Parsing Yandex",
    parsingGoogle: "Google parsing",
    instagram: "Instagram",
    facebook: "Facebook",
    vk: "Vkontakte",
    ok: "Одноклассники",
    bookmakers: "Bookmaker offices",
    avito: "Avito",
    amazon: "Amazon",
    stavki: "Rates",
    serfing: "Web surfing",
    tinder: "Tinder",
    twitch: "Twitch",
    ownPurpose: "Own purpose"
  },
  common: {
    onlineChat: "Online chat"
  },
  notFound: "Not found",
  buttons: {
    confirm: "Confirm",
    save: "Save",
    cancel: "Cancel",
    send: "Send"
  },
  contacts: {
    title: "Contact us",
    description: "If you have any questions, feel free to reach out through our feedback form or any other convenient method of communication. Our managers are online 24/7 to assist and address any concerns you might have.",
    feedbackForm: {
      formTitle: "Feedback form",
      nameField: "Name",
      emailField: "Email",
      messageField: "Enter your message...",
      submit: "Submit",
      success: "Message was sent",
      invalidEmail: "Email is not valid",
      invalidMessage: "Message must be at least 10 characters"
    }
  },
  select: {
    noOptionsMessage: "No options"
  },
  paymentSuccessPage: {
    proxyActivation: "Proxy activation for 1-3 min.",
    thanksForPayment: "Thanks for the payment!",
    orderDetails: "We have successfully processed your payment and are in the process of activating IP addresses for use. We have automatically registered you. All data is duplicated on e-mail and personal account",
    cabinetMessage: "Personal account with the functionality of tracking the status of the order, the remaining period of using the proxy and managing operations",
    personalArea: "Personal area",
    ourAdvantages: "Our advantages",
    securityAdvantage: "Secure payment system for all clients",
    supportAdvantage: "Support service 24/7",
    privacyAdvantage: "We provide full anonymity",
    addFeedback: "Please take a second to evaluate the service:",
    feedbackMessage: "We will be very grateful for your feedback about our service on one of the sites",
    postReview: "Post a review",
    thanksForOrder: "Thank you for the order!",
    orderMessage: "The progress of the order can be monitored on this page or simply close it, we will additionally inform you by email. This usually takes 1-2 minutes.",
    ips: "IPs",
    statusTitles: {
      orderCreation: "Order creation",
      orderPayment: "Order payment",
      proxySelection: "Proxy selection",
      proxyActivationInitiated: "Proxy activation initiated",
      orderCreated: "Order created",
      orderPaid: "Order paid",
      proxiesSelected: "Proxies selected",
      proxiesActivated: "Proxies activated"
    },
    statusDescriptions: {
      paymentResponse: "Waiting for response from payment system",
      paymentConfirmed: "Payment confirmed",
      purposeRequired: "Purpose of use clarification required",
      outOfStock: "Out of stock!",
      prohibitedPurpose: "Prohibited purpose!"
    }
  },
  orderProgressPage: {
    orderCreated: {
      successTitle: "Order created"
    },
    orderPaid: {
      successTitle: "Order paid",
      waitingTitle: "Paying of the order",
      successDescription: "Payment confirmed",
      waitingDescription: "Waiting for a response from the payment system",
    },
    proxyPicking: {
      successTitle: "Proxies picked",
      waitingTitle: "Proxies picking started"
    },
    proxyEnabling: {
      successTitle: "Proxies enabled",
      waitingTitle: "Proxies enabling started"
    },
    goalСlarification: "Purpose of use_requires clarification",
    purposePlaceholder: "Purpose of use",
    save: "Save",
    noProxy: "Out of stock",
    forbiddenTarget: "Forbidden target!",
    waitForClient: "Pending clarification",
    ips: "IPs",
    goalSuccessSending: "New goal successfully sent",
    invalidGoal: "Invalid goal was selected!",
    proxyAlreadyEnabled: "Your proxies are already enabled!",
    noOrderMessage: "Apologies, the order information is no longer accessible."
  }
};

const translationRu = {
  header: {
    logoAria: "Перейти на главную страницу",
    logoAlt: "Логотип компании Proxy Ipv4",
    ariaBurger: "Открыть или закрыть навигационное меню",
    solutions: {
      solutionsNav: "Решения",
      tgBot: "Телеграм бот",
      addonsTitle: "Аддоны:"
    },
    menu: {
      proxyNav: {
        name: "Купить прокси",
        indicator: "Индикатор открытия выпадающего меню",
        subName: "Прокси",
        subNameMobile: "Мобильные прокси"

      },
      price: "Цены",
      feedback: "Отзывы",
      faq: "FAQ",
      contacts: "Контакты",
      back: "Назад"
    },
    cabinet: {
      login: "Войти",
      loginIsAuth: "Личный кабинет",
      logout: "Выйти"
    },
    formElem: {
      email: {
        label: "Ваш email",
        placeholder: "Введите ваш e-mail"
      },
      password: {
        label: "Ваш пароль",
        placeholder: "Введите пароль"
      },
      repeatPassword: {
        label: "Повторите пароль",
        placeholder: "Введите пароль"
      },
      newPassword: {
        label: "Ваш новый пароль",
        placeholder: "Введите пароль"
      },
      repeatNewPassword: {
        label: "Повторите новый пароль",
        placeholder: "Введите пароль"
      },
      wrongPassword: "Пароли не совпадают",
      invalidEmail: "Неверный адрес электронной почты",
      invalidPassword: "Неверный пароль",
    },
    topList: "Самые популярные",
    subList: "Все страны",
    modalCabinet: {
      title: "Авторизация",
      enterBtn: "Войти",
      remember: "Запомнить меня",
      recoveryBtn: "Забыли пароль?",
      textSignUp: "Нет учётной записи?",
      signUpBtn: "Создать"
    },
    modalRecovery: {
      title: "Восстановление вашего пароля",
      recoveryBtn: "Восстановить"
    },
    modalSingUp: {
      title: "Регистрация",
      singUpBtn: "Создать учётную запись",
      textLogin: "У вас есть учётная запись?",
      loginBtn: "Войти",
      successMessage: "Благодарим вас за регистрацию на нашем сайте!\n" +
        "Мы отправили данные для входа на вашу почту."
    },
    notifications: {
      title: "Уведомления",
      clearAll: "Очистить все",
      expand: "Развернуть",
      collapse: "Свернуть",
      noData: "Новые сообщения отсутствуют.",
      showMore: "Показать ещё"
    }
  },
  footer: {
    logoAria: "Перейти на главную страницу",
    logoAlt: "Логотип компании Proxy Ipv4",
    privacyPolicy: {
      title: "Политика конфиденциальности",
      lang: "ru"
    },
    publicOffer: {
      title: "Публичная оферта",
      lang: "ru"
    },
    termsOfUse: {
      title: "Условия использования",
      lang: "ru"
    },
    returnPolicy: {
      title: "Политика возвратов",
      lang: "ru"
    },
    cookiePolicy: {
      title: "Cookie Policy",
      lang: "ru"
    }
  },
  main: {
    buyBtn: "Купить прокси",
    selectProxy: {
      IPv4: "Прокси IPv4",
      IPv6: "Прокси IPv6",
      MOBILE: "Мобильные прокси",
      ISP: "ISP прокси"
    },
    seo: {
      showAllButton: "Показать все"
    },
    sortOptions: {
      price: "цене",
      popular: "популярности"
    },
    days: "Дней",
    agreeWithRules: {
      iAgree: "Я согласен с",
      and: "и",
      privacyPolicy: "политикой конфиденциальности",
      termsOfUse: "условиями использования",
      warningMessage: "Примите условия использования чтобы продолжить."
    },
  },
  orderForm: {
    title: "Форма заказа",
    fullPrice: "К оплате",
    payBtn: "Оплатить",
    rotation: "Обновление IP :",
    operator: "Оператор :",
    description: "Заказ будет обработан автоматически сразу после подтверждения оплаты."
  },
  formPlaceholders: {
    country: "Выбрать страну",
    protocol: "Выберите протокол",
    rentPeriod: "Срок аренды",
    operators: "Оператор",
    quantity1: "Количество",
    quantity2: "шт",
    rotation: "Время обновления IP",
    proxyForField: "Прокси для",
    proxyGoalField: {
      select: "Цель использования",
      input: "Введите цель"
    },
    authField: "Способ авторизации",
    email: "Ваш e-mail",
    paymentField: "Способ оплаты",
    paymentAmount: "Введите сумму",
    currency: "Валюта",
    paymentDisabled: "Сначала укажите ваш e-mail",
    search: "Поиск",
    enterCountryName: "Введите название страны"
  },
  prices: {
    title: "Цены на прокси",
    sort: "Сортировать по:",
    modalTitle: "Выберите страну",
    more: "Еще",
    seo: {
      title: "Для SEO",
      subGoal: "Яндекс, Google, KeyCollector и другие...",
      subGoalIPv6: "Google, Scrapebox, Trafficbot, Yahoo, и другие...",
      advantages: [
        "Неограниченный трафик",
        "HTTP/S, SOCKS5",
        "В одни руки",
        "Поддержка 24/7"
      ]
    },
    smm: {
      title: "Для SMM",
      subGoal: "Instagram, Вконтакте, Одноклассники, Facebook и другие...",
      subGoalIPv6:
        "Instagram, Telegram, LinkedIn, Facebook, YouTube  и другие...",
      advantages: [
        "Неограниченный трафик",
        "HTTP/S, SOCKS5",
        "В одни руки",
        "Поддержка 24/7"
      ]
    },
    other: {
      title: "Другие задачи",
      subGoal:
        "Букмекерские конторы, Coinlist, Wildberries, Amazon, Ebey и другое...",
      subGoalIPv6: "Google сервисы, Netflix, Alibaba, Walmart и другое...",
      advantages: [
        "Неограниченный трафик",
        "HTTP/S, SOCKS5",
        "В одни руки",
        "Поддержка 24/7"
      ]
    },
    buyBtn: "Купить прокси",
    query: "Выберите страну, пожалуйста",
    minQuantityIPv6: "Минимальный заказ для IPv6 10 шт.",
    notCurrentPaymantBalanceData: "Минимальная сумма пополнения 1$.",
    lowAmount:
      "Внимание! Заказы на сумму до 5$ оплачиваются с баланса личного кабинета. ",
    lowAmountBtn: {
      cabinet: "Личный кабинет",
      replenish: "Пополнить баланс"
    },
    notAuthBalance:
      "Для использования системы баланса необходимо авторизоваться",
      infoModalSelectBalance: "Измените способ оплаты на \"Баланс\"",
    lowBalance: {
      main: "Недостаточный баланс. Для продолжения пополните баланс или смените платежную систему",
      title: "Недостаточно средств на балансе",
      balance: "Баланс",
      textContinue:
        "Нажмите «продолжить» для смены платежной системы, и повторите ваш заказ.",
      textReplenish:
        "Что бы пополнить баланс, нажмите «пополнить» и завершите операцию.",
      btnContinue: "Продолжить",
      btnReplenish: "Пополнить"
    },
    priceItem: {
      proxyQuantity: "Количество прокси",
      price: "Цена за прокси",
      pcs: "шт",
      popular: "Популярный",
      yourQuantity: "Ваше количество",
      enterpriseDescription: "Пожалуйста, свяжитесь с нашим отделом продаж что бы получить индвидуальные условия на использование сервиса",
      enterpriseModal: {
        name: "Имя",
        selectSocials: "Тип связи",
        contacts: "Контакты для связи",
        describeResources: "Опишите необходимые ресурсы для ваших целей",
        companyActivity: "Опишите деятельность вашей компании",
        other: "Прочее",
        send: "Отправить",
        successfulSendingMessage: "Спасибо за обращение.\n" +
          "Свяжемся с вами как можно скорее.",
        // buttonText: "Связаться с нами"
        buttonText: "Связаться с нами"
      }
    }
  },
  mobileProxy: {
    chanel: "Канал",
    days: "дней",
    operator: "Оператор",
    changeIP: "Изменение IP",
    traffic: "Трафик",
    price: "Стоимость",
    limit: "Безлимитный",
    changeIPTime: "мин",
    changeIPauto: "по ссылке",
    for: "за"
  },
  advantages: { title: "Преимущества IPv4" },
  proxyManager: {
    title: "Простое управление своими прокси",
    ipv6Message: "Простое управление своими IPv6 прокси",
    ispMessage: "Простое управление своими ISP прокси",
    mobileMessage: "Простое управление своими мобильными прокси",
    countryTypeMessage: "Простое управление своими _ прокси",
    list: [
      "Автоматизация с помощью клиентского API",
      "Баланс и вся финансовая история",
      "Выгрузка в любом удобном формате",
      "Комментирование и сортировка по папкам"
    ]
  },
  reviews: {
    title: "Отзывы о нас",
    titleMain: "Отзывы Proxy-IPv4",
    togglerAriaLabel: "Открыть или закрыть вопрос",
    allReviews: "Все отзывы"
  },
  faq: {
    title: "Отвечаем на ваши вопросы",
    description:
      "Просмотрите список часто задаваемых вопросов, возможно вы найдете свой.",
    ask: "Задать свой вопрос"
  },
  cabinet: {
    profile: {
      title: "Профиль",
      formTitle: "Сменить текущий пароль",
      formBtn: "Сменить пароль",
      wrongPassword: "Пароли не совпадают",
      wrongPasswordLength: "Пароль должен быть не менее 6 символов",
      wrongPasswordChars: "Пароль должен содержать только латинские буквы и цифры",
      wrongEmail: "Неверный логин или пароль",
      theSamePass: "Старый и новый пароли совпадают",
      edit: "Редактировать",
      save: "Сохранить",
      close: "Отменить",
      email: {
        title: "Электронная почта:",
        info: "Ваш текущий адрес электронной почты:",
        new: "Ваша новая почта",
        enterEmail: "Введите адрес новой почты",
        password: "Ваш текущий пароль",
        enterPassword: "Ведите текущий пароль"
      },
      password: {
        title: "Ваш пароль:",
        info: "Последний раз вы обновляли пароль:",
        year: "год назад",
        years: "года назад",
        yearsTwo: "лет назад",
        month: "месяц назад",
        months: "месяца назад",
        monthsTwo: "месяцев назад",
        day: "день назад",
        days: "дня назад",
        daysTwo: "дней назад",
        today: "сегодня"
      }
    },
    newOrder: {
      tabName: "Новый заказ"
    },
    distribution: {
      title: "Рассылка почты",
      descriptions: "Настройки получения уведомлений",
      formTitle: "Рассылка почты",
      formBtn: "Сохранить",
      system: "Системные уведомления",
      other: "Прочие"
    },
    telegram: {
      title: "Телеграм",
      descriptions: "Уведомления доступны после",
      bot: "Телеграм бот",
      notifications: "Уведомления",
      turnOn: "Включить",
      turnOff: "Отключить",
      botLink: "авторизации в боте",
      formBtn: "Сохранить"
    },
    format: {
      title: "Формат выгрузки",
      example: "Пример:",
      variables: "Доступные переменные:",
      textClicking: "(по клику на переменную она вставляется в поле ввода)"
    },
    documentation: {
      title: "API",
      main: {
        whiteIp: "Белый IP",
        placeholderKey: "нажмите Сгенерировать ключ",
        placeholderIP: "введите IP",
        button: {
          generateKey: "Сгенерировать ключ",
          save: "Сохранить",
          link: "API документация"
        },
        descriptions: {
          key: "Не передавайте этот ключ третьим лицам",
          infoOne:
            "Вы можете разрешить доступ к API только с определенного IP-адреса.",
          infoTwo: "Вы можете добавить до 3 IP-адресов через запятую."
        }
      },
      descriptionsAPI: {
        title: "API Документация",
        mainTitle: "Общие запросы",
        ipv4Title: "IPv4 запросы",
        ispTitle: "ISP запросы",
        ipv6Title: "IPv6 запросы",
        mobileTitle: "Mobile запросы",
        info: "Api позволит вам интегрировать покупку и продление прокси в ваш сервис, либо приложение. Взаимодействие партнера с системой, равно как и системы с партнером происходит посредством “application/json” запросов и ответов. Все общение происходит в кодировке UTF-8. В системе реализована защита от DOS аттак, разрешено не более 60 запросов в секунду. В случае отправке больше 60 в секунду, в ответ придет #error_id=2#. #Нужен лимит больше?#",
        responseBodyExample: "Пример тела ответа",
        responseParams: "Параметры ответа",
        reqwestParams: "Параметры запроса",
        reqwestExample: "Пример запроса",
        reqwestExampleBody: "Пример тела запроса",
        required: "Обязательно",
        errorsTitle: "Возможные ошибки",
        errorExample: "Пример ошибки",
        errorIds: "ID ошибок"
      }
    },
    notifications: {
      tabName: "Уведомления"
    },
    orders: {
      title: "Таблица заказов",
      formTitle: "Продлить прокси",
      formBtn: "Продлить",
      column: {
        copy: "Скопировать айпи",
        link: "Ссылка",
        rotation: "Ротация",
        copyLink: "Скопировать ссылку",
        ip: {
          name: "IP",
          imgAlt: "Подсказка",
          login: "Логин",
          password: "Пароль",
          ip: "IP авториз.",
          port: "Порт",
          http: "HTTP/S",
          socks: "SOCKS5",
          protocol: "Протокол",
          ipStatus: "Состояние IP",
          active: "Активный",
          ending: "Заканчивается"
        },
        autoRenewal: {
          title: "Автопродление",
          enabled: "Включено",
          disabled: "Выключено"
        },
        notes: {
          title: "Заметки",
          placeholder: "Введите свою заметку"
        },
        country: "Страна IP",
        term: "Активен до",
        date: "Дата",
        amount: "Сумма",
        balanceOperation: {
          title: "Тип операции",
          plus: "Пополнение баланса",
          minus: "Оплата услуги",
          plusAdmin: "Пополнение баланса администратором",
          minusAdmin: "Списание баланса администратором"
        },
        state: {
          title: "Состояние IP",
          active: "Активен",
          inactive: "Неактивен"
        }
      },
      rowPerPage: "Прокси на страницу",
      separatorText: "из",
      export: "Выгрузка",
      save: "Сохрвнить",
      download: "Скачать",
      cancel: "Отменить",
      applyBtn: "Применить",
      slectFileType: "Тип файла",
      noDataComponent: "Тут пока ничего нет",
      folders: {
        folderSelectLabel: "Название папки",
        moveToFolderSelectLabel: "Переместить в папку",
        generalFolder: "Все",
        createFolder: "Создать новую папку",
        modals: {
          createFolder: "Создать папку",
          editName: "Редактировать название",
          inputLabel: "Название папки",
          deleteNotification: "Вы уверенны, что хотите удалить папку",
          save: "Сохранить",
          cancel: "Отмена",
          remove: "Удалить",
          folderAlreadyExist: "Папка с таким названием уже существует."
        }
      },
      renewalErrorMessages: {
        noCountry: "Извините, функция недоступна для таких стран: _",
        noIp: "Извините, продление этого ip недоступно в данный момент",
        noMobileOperator: "Извините, мобильный оператор _ недоступен в данный момент",
        unexpectedError: "Что то пошло не так"
      },
      autoExtending: {
        title: "Вставить списком",
        description: "Напишите перечень IP, которые хотите продлить",
        placeholder: "Введите IP-адреса...",
        noMatches: "Совпадений не найдено",
        allFound: "Найдено -found- IP-адресов",
        partFound: "Найдено -found- из -entered- введённых IP",
        modals: {
          confirm: {
            autoRenewal: "Автопродление",
            selectedProxy: "Выбрано прокси:",
            pcs: "шт",
            autoRenewalConfirm: "Вы подтверждаете включение автопродления?",
            confirmationHintPart1: "Выбранный прокси будет продлен на",
            confirmationHintPart2: "средства будут сняты с вашего счета",
            hint: "Продлеваться прокси будут на тот же срок, что и был сам заказ.",
            hintExample: "Пример: купили на 7 дней, продление будет также каждые 7 дней.",
            autoRenewalButton: "Автопродление",
            cancelButton: "Отмена"
          },
          cancel: {
            confirmCanceling: "Вы подтверждаете отмену атопродления? ",
            hint: "Прокси будут активны до конца срока действия и не будут продлены на следующий период.",
            confirmButton: "Подтвердить",
            cancelButton: "Отмена"
          },
          noCash: {
            description: "На вашем балансе не достаточно средств для использования функции автопродления.",
            hint: "Пожалуйста пополните баланс.",
            cancel: "Отмена",
            topUp: "Пополнить"
          }
        }
      },
      filter: {
        all: "Все",
        resetButton: "Сбросить",
        saveButton: "Сохранить",
        filterByNote: "Фильтровать по заметке",
        enterNote: "Введите заметку...",
        dateFilter: "Фильтровать по дате",
        dateErrorMessage: "Недопустимый формат даты",
        inValidDateRange: "Недопустимый диапазон дат",
        from: "От",
        to: "До",
        country: "Страна",
        ipStatus: "Состояние IP"
      },
      access: {
        title: "Доступ",
        descriptionLoginPass: "Ваш логин и пароль авторизации к данным прокси.",
        descriptionIp: "Добавьте IP адрес используя форму ниже и сохраните. Изменения вступят в силу автоматически в течении 1 минуты.",
        loginTabTitle: "Логин/пароль",
        ipTabTitle: "По IP",
        loginField: "Логин",
        passwordField: "Пароль",
        clearData: "Очистить данные",
        save: "Сохранить",
        cancel: "Отмена",
        remove: "Удалить",
        successMessage: "Успех",
        loginExist: "Логин уже существует",
        deleteNotification: "Вы уверенны, что хотите удалить IP адрес",
        addIp: "Добавить IP",
        accessDataSaved: "Ваши авторизационные данные успешно сохранены."
      },
      notes: {
        note: "Заметка",
        comment: "Комментарий",
        enterNote: "Введите вашу заметку"
      },
      ipReplacement: {
        title: "Замена IP",
        description: "Пожалуйста, выберите причину замены из предложенного списка или укажите свой вариант используя соответствующий вариант.",
        commentPlaceholder: "Комментарий",
        successfullySent: "Запрос на замену ip успешно отправлен",
        ipReplacementReason: "Пожалуйста, укажите причину смены IP-адреса",
        somethingWentWrongMsg: "Что-то пошло не так, попробуйте позже",
        tooOftenMsg: "IP можно менять раз в сутки. Вы уже отправили на замену следующие ips:",
        alreadyRequestedMsg: "Заявка на замену этих айпи уже находится на рассмотрении:",
        reasonTitle: "Почему вы хотите заменить IP:",
        reasons: {
          notWork: "Не работает",
          incorrectLocation: "Некорректная локация",
          changeNetwork: "Хочу изменить сеть",
          lowSpeed: "Низкая скорость",
          custom: "Ваш вариант"
        }
      }

    },
    balance: {
      description: "Минимальная сумма пополнения 5$",
      title: "Баланс",
      label: "Ваш баланс:",
      button: "Пополнить",
      lines: "строчек"
    },
    faq: {
      tabName: "FAQ"
    }
  },
  paymentModal: {
    success: "Спасибо за оплату, ваш заказ уже обрабатывается!",
    cancel: "Оплата не прошла, ваш платеж не получен",
    pending: "Пожалуйста, подождите!",
    replenishment: {
      success: "Спасибо, ваш баланс был пополнен на сумму",
      cancel: "Оплата не прошла, ваш платеж не получен",
      pending: "Пожалуйста, подождите!"
    }
  },
  notifications: {
    wrongIP: "введите корректный IP",
    wrongIPInput: "введите корректный IP",
    wrongEmailInput: "введите корректный e-mail",
    wrongEmail: "введите корректный эмейл",
    error: "Что-то пошло не так!",
    errorCaptcha: "Что-то пошло не так, пожалуйста попробуйте позже!",
    errorMinLength: "цель должна быть от 5 символов",
    errorUserPaymentSystem: "Проблема на стороне платежной системы , выберите другую или попробуйте позже!",
    copy: "Скопировано!",
    loginError: "Неправильный адрес электронной почты или пароль!",
    registerError: "Ошибка при попытке регистрации!",
    registerErrorWrongEmail: "Введите существующую почту",
    email: "Проверьте почту! Вам отправлено уведомление!",
    passwordChange: "Пароль успешно изменен!",
    emailChange: "Почта успешно изменина",
    distributionChange: "Рассылка была успешно изменена!",
    minPrice: "Минимальная цена для вашего заказа составляет",
    captcha: "Вы робот!",
    wrongIp: "Вы ввели некорректный IP адрес!",
    incorrectFormat: "Некорректный формат!",
    serverError: "Временная неполадка на сервере, пожалуйста попробуйте позже.",
    logout: "Вы собираетесь выйти из вашей учетной записи.",
    documentation: {
      key: "Необходимо сгенерировать ключь!",
      amount: "Максимальное количестко Ip 3шт.!",
      ip: "Введены некорректные Ip!",
      success: "Данные успешно сохранены!",
      changeKey: "Внимание прежний Токен будет перезаписан!",
      continue: "Продолжить",
      cancel: "Отменить ",
      minPriceForPaymentSystem: "Минимальная сумма пополнения для данной платёжной системы — "
    },
    tempMail: "Извините, использование времнных эмейлов невозможно.",
    invalidIp: "Этот IP не может использоваться как IP авторизации",
  },
  subgoals: {
    keyCollector: "KeyCollector",
    parsingYandex: "Парсинг Яндекс",
    parsingGoogle: "Парсинг Google",
    instagram: "Инстаграм",
    facebook: "Facebook",
    vk: "Вконтакте",
    ok: "Одноклассники",
    bookmakers: "Букмекерские конторы",
    avito: "Авито",
    amazon: "Амазон",
    stavki: "Ставки",
    serfing: "Веб серфинг",
    tinder: "Тиндер",
    twitch: "Твитч",
    ownPurpose: "Своя цель"
  },
  common: {
    onlineChat: "Онлайн чат"
  },
  notFound: "Не найдено",
  buttons: {
    confirm: "Подтвердить",
    save: "Сохранить",
    cancel: "Отмена",
    send: "Отправить"
  },
  contacts: {
    title: "Свяжитесь с нами",
    description: "Задайте любой интересующий вопрос через форму обратной связи или любым удобным способом связи. Наши менеджеры работают для вас 24/7 и помогут в решении любой задачи.",
    feedbackForm: {
      formTitle: "Форма обратной связи",
      nameField: "Имя",
      emailField: "Email",
      messageField: "Введите ваше сообщение...",
      submit: "Отправить",
      success: "Сообщение отправлено",
      invalidEmail: "Введите правильный адрес электронной почты",
      invalidMessage: "Сообщение должно быть не менее 10 символов"
    }
  },
  select: {
    noOptionsMessage: "Нет опций"
  },
  paymentSuccessPage: {
    proxyActivation: "Активация прокси за 1-3 мин.",
    thanksForPayment: "Спасибо за оплату!",
    orderDetails: "Мы успешно обработали ваш платеж и находимся в процессе активации IP-адресов для использования. Мы автоматически зарегистрировали вас. Все данные дублируются на электронную почту и личный кабинет",
    cabinetMessage: "Личный кабинет с функционалом отслеживания статуса заказа, оставшегося срока использования прокси и управления операциями",
    personalArea: "Личный кабинет",
    ourAdvantages: "Наши преимущества",
    securityAdvantage: "Безопасная система оплаты для всех клиентов",
    supportAdvantage: "Круглосуточная служба поддержки 24/7",
    privacyAdvantage: "Мы предоставляем полную анонимность",
    addFeedback: "Пожалуйста, уделите секунду на оценку сервиса:",
    feedbackMessage: "Мы будем очень признательны за ваш отзыв, о нашем сервисе, на одной из площадок",
    postReview: "Опубликовать отзыв",
    thanksForOrder: "Спасибо за заказ!",
    orderMessage: "Прогресс выполнения заказа можно наблюдать на этой странице или просто ее закрыть, мы дополнительно проинформируем вас на эмейл. Обычно это занимает 1-2 минуты.",
    ips: "Шт",
    statusTitles: {
      orderCreation: "Создание заказа",
      orderPayment: "Оплата заказа",
      proxySelection: "Подбор прокси",
      proxyActivationInitiated: "Началось включение прокси",
      orderCreated: "Заказ создан",
      orderPaid: "Заказ оплачен",
      proxiesSelected: "Прокси подобрались",
      proxiesActivated: "Прокси включены",
    },
    statusDescriptions: {
      paymentResponse: "Ожидание ответа от платёжной системы",
      paymentConfirmed: "Оплата подтверждена",
      purposeRequired: "Требуется уточнение цели использования",
      outOfStock: "Нет в наличии!",
      prohibitedPurpose: "Запрещённая цель!"
    }
  },
  orderProgressPage: {
    orderCreated: {
      successTitle: "Заказ создан"
    },
    orderPaid: {
      successTitle: "Заказ оплачен",
      waitingTitle: "Оплата заказа",
      successDescription: "Оплата подтверждена",
      waitingDescription: "Ожидание ответа от платёжной системы",
    },
    proxyPicking: {
      successTitle: "Прокси подобрались",
      waitingTitle: "Подбор прокси"
    },
    proxyEnabling: {
      successTitle: "Прокси включены",
      waitingTitle: "Началось включение прокси"
    },
    goalСlarification: "Требуется_уточнение цели использования",
    purposePlaceholder: "Цель использования",
    save: "Сохранить",
    noProxy: "Нет в наличии!",
    forbiddenTarget: "Запрещённая цель!",
    waitForClient: "Ожидает уточнения",
    ips: "Шт",
    goalSuccessSending: "Новая цель успешно отправлена",
    invalidGoal: "Выбрана невалидная цель!",
    proxyAlreadyEnabled: "Ваши прокси уже включены!",
    noOrderMessage: "Извините, информация о заказе больше недоступна."
  }
};

const TranslationReducer = createSlice({
  name: "index",
  initialState: {
    translation: {
      en: translationEn,
      ru: translationRu
    },
    languages: {
      en: { code: "en", name: "English" },
      ru: { code: "ru", name: "Russian" }
    },
    ruCountries: ["az", "hy", "be", "kk", "ky", "ru", "tg", "uz", "uk"]
  },
  reducers: {
    changeLanguage(state, action) {
      const location = window.location.pathname.replace(
        `/${action.payload.lang}/`,
        `/${action.payload.activeLang}/`
      );
      action.payload.navigate(location + window.location.search || "" );
    }
  }
});

export const TranslationReducerActions = {
  ...TranslationReducer.actions
};
export default TranslationReducer.reducer;
