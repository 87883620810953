import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AppLayout } from "../layout/AppLayout";
import { IndexLayout } from "../layout/indexLayout";
import { useDetectDefaultLang } from "../hooks/useDetectDefaultLang";
import { PerfectMoney } from "../components/common/PerfectMoney/PerfectMoney";
import { WebMoney } from "../components/common/WebMoney/WebMoney";
import { AppRoutes, CabinetRoutes, OtherRoutes } from "./routes";

export const Router = () => {
  const { pathname } = useLocation();
  const appRoutes = () =>
    AppRoutes.map((route) => (
      <Route key={route.path} path={route.path} element={<route.component />} />
    ));

  const cabinetRoutes = () =>
    CabinetRoutes.map((route) => (
      <Route key={route.path} path={route.path} element={<route.component />} />
    ));

  const documentationRoutes = () =>
    OtherRoutes.map((route) => (
      <Route key={route.path} path={route.path} element={<route.component />} />
    ));
  return (
    <Routes>
      <Route
        path="/payment/:status/"
        element={<Navigate to={`/${useDetectDefaultLang()}${pathname}`} />}
      />
      <Route element={<IndexLayout />}>
        <Route element={<AppLayout />}>
          {cabinetRoutes()}
        </Route>
        <Route element={<AppLayout />}>
          {documentationRoutes()}
        </Route>{" "}
        <Route element={<AppLayout />}>
          {appRoutes()}
        </Route>
      </Route>
      <Route
        path="/process/payment/webmoney/:paymentId"
        element={<WebMoney />}
      />
      <Route
        path="/process/payment/perfectMoney/:paymentId"
        element={<PerfectMoney />}
      />
      <Route
        path="*"
        element={<Navigate to={`/${useDetectDefaultLang()}/`} />}
      />
    </Routes>
  );
};
