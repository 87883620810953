import { useEffect } from "react";
import { useSelector } from "react-redux";
// import { Button } from "../../../../components/ui/Buttons/Button/Button";
// import { ApiService } from "../../../../services/ApiService";
import { useDispatchedActions } from "../../../../hooks/useDispatchedActions";
import { History } from "./History/History";
import { Top } from "./Top/Top";
import "./Balance.scss";

export const Balance = () => {
  const { fiveDollarLogick } = useSelector((state) => state.content.proxies);
  const { setUseFiveDollarLogick } = useDispatchedActions();
  // const [paddleData, setPaddleData] = useState(null);

  const controller = new AbortController();

  if (fiveDollarLogick?.enabled) {
    setUseFiveDollarLogick(false);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => controller.abort(), []);

  // const initiatePay = () => {
  //   if (!paddleData) return;
  //
  //   window.Paddle.Checkout.open({
  //     // eslint-disable-next-line
  //     return_url: "/",
  //     product: paddleData.subscription_plan_id,
  //     // eslint-disable-next-line camelcase
  //     passthrough: paddleData.passthrough,
  //     email: paddleData.email
  //   });
  // };
  //
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data, status } = await ApiService.getPaddleData();
  //
  //       if (status !== 200) {
  //         throw new Error();
  //       }
  //
  //       setPaddleData(data);
  //
  //     } catch (e) {
  //       // eslint-disable-next-line no-console
  //       console.log(e);
  //     }
  //   })();
  // }, []);

  return (
    <>
      <Top controller={controller} />
      {/*<Button onClick={initiatePay}>Paddle</Button>*/}
      <History controller={controller} />
    </>
  );
};
